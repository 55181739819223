/* Common imports */

import React from "react";

/* mui imports */

import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Divider,
	Typography,
	Slide,
	Button,
	useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Brightness1Icon from "@mui/icons-material/Brightness1";

/* redux imports */
import StateInterface from "../../redux-magic/state-interface";
import {
	SearchFilterId,
	SearchFilterOption,
	SearchFilterOptionTextActive,
} from "../../redux-magic/sub-interfaces/search-types";

import {
	updateSearchFilterActiveOptionsThunk,
	updateSearchFilterAppliedThunk,
	updateSearchFilterSelectedOptionsThunk,
	updateSearchFilterVisibilityThunk,
	updateSearchFilterIsAccordionOpenThunk,
} from "../../redux-magic/thunks";

import {
	residential_options_active,
	commercial_options_active,
	agricultural_options_active,
	warehousing_options_active,
	industrial_options_active,
} from "../../lib/data/search-filters/_e_property_type";

/* stylings */

const CustomAccordion = styled(Accordion)(({ theme }) => ({
	borderRadius: "16px",
	boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
	"&:: before": {
		display: "none",
	},
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
	padding: "var(--Spacing-spacing-sm, 8px) var(--Spacing-spacing-md, 16px)",
	margin: "0rem",
}));

const CustomCategoryIcon = styled(CategoryOutlinedIcon)(({ theme }) => ({
	marginRight: "1rem",
	color: theme.palette.primary.main,
}));

const CustomArrowIcon = styled(KeyboardArrowDownOutlinedIcon)(({ theme }) => ({
	width: "1.5rem",
	height: "1.5rem",
	borderRadius: "4px",
	color: theme.palette.mode == "dark" ? "#FFFFFF" : "#623816",
	backgroundColor: "#FC801929",
}));

const CustomSelectedIcon = styled(Brightness1Icon)(({ theme }) => ({
	color: theme.palette.primary.main,
	width: "8px",
	marginTop: "-0.25rem",
	marginLeft: "0.25rem",
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	flexWrap: "wrap",
	padding: "1rem",
	margin: "0rem",
	gap: "var(--Spacing-spacing-md, 16px)",
}));

const CheckBoxDiv = styled(Button)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "0.5rem",
	padding: "var(--Spacing-spacing-xs, 4px)",
	gap: "var(--Spacing-spacing-xs, 4px)",
	cursor: "pointer",
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "0.813rem",
	fontWeight: "400",
	lineHeight: "1.125rem",
	padding: "3px 6px",
	textTransform: "capitalize",
	color: theme.palette.mode == "dark" ? "#FFFFFF" : "#623816",
}));

const DoneIcon = styled(DoneOutlinedIcon)(({ theme }) => ({
	color: "#623816",
	height: "1rem",
	width: "1rem",
}));

const AddIcon = styled(AddOutlinedIcon)(({ theme }) => ({
	color: theme.palette.mode == "dark" ? "#FFFFFF" : "#623816",
	height: "1rem",
	width: "1rem",
}));

export const _d_property_category = ({
	search_id,
	property_category,
	dispatch,
}: {
	search_id: SearchFilterId;
	property_category: StateInterface["search_filters_state"]["property_category"];
	dispatch: Function;
}) => {
	const theme = useTheme();
	const [slide, setSlide] = React.useState<boolean>(property_category.is_accordion_open);

	const handleUpdatePropertyCategory = (value: Array<SearchFilterOptionTextActive>) => {
		let selected_options: Array<SearchFilterOption> = value.map((option) => {
			let { is_selected, ...rest } = option;
			return rest;
		});

		const selectedOptionId = value[0]?.id;

		const updatedActiveOptions = property_category.active_options.map((item) => ({
			...item,
			is_selected: item.id === selectedOptionId,
		}));

		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: selected_options,
			}),
		);

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions: updatedActiveOptions,
			}),
		);

		const propertyTypeOptions =
			selectedOptionId === "residential"
				? residential_options_active
				: selectedOptionId === "commercial"
					? commercial_options_active
					: selectedOptionId === "agricultural"
						? agricultural_options_active
						: selectedOptionId === "warehousing"
							? warehousing_options_active
							: selectedOptionId === "industrial"
								? industrial_options_active
								: residential_options_active;

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: "property_type",
				newOptions: propertyTypeOptions,
			}),
		);

		if (
			property_category.selected_options.length === 0 ||
			property_category.selected_options[0]?.id !== selectedOptionId
		) {
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "property_type",
					newOptions: [],
				}),
			);
		}
	};

	React.useEffect(() => {
		// lets check if the property category is selected
		let isPropertyCategorySelected = property_category
			? property_category.selected_options.length !== 0
				? true
				: false
			: false;

		// lets set the property category is applied to true if the property category is selected
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: search_id, isApplied: isPropertyCategorySelected }));

		//lets check if the property category is affects any other filters
		let propertyCategoryAffects = property_category
			? (property_category.affects[0] as SearchFilterId)
			: (" " as SearchFilterId);

		// lets update the visibility of the affected filter
		dispatch(
			updateSearchFilterVisibilityThunk({
				searchFilterId: propertyCategoryAffects,
				isVisible: isPropertyCategorySelected,
			}),
		);

		// lets set the accordon value of the affected filter to true if the property category is selected

		dispatch(
			updateSearchFilterIsAccordionOpenThunk({
				searchFilterId: propertyCategoryAffects,
				isAccordionOpen: isPropertyCategorySelected,
			}),
		);
	}, [property_category, search_id, dispatch]);

	return (
		<CustomAccordion
			disableGutters={true}
			square={true}
			defaultExpanded={property_category.is_accordion_open as boolean}
			onChange={() => setSlide(!slide)}
		>
			<CustomAccordionSummary
				expandIcon={<CustomArrowIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				<CustomCategoryIcon /> Property Category {property_category.is_applied && <CustomSelectedIcon />}
			</CustomAccordionSummary>
			<Divider
				sx={{
					borderBottom: "1px solid var(--Other-New-Divider, rgba(255, 255, 255, 0.12))",
				}}
			/>
			<Slide
				direction="up"
				in={slide}
				mountOnEnter
				unmountOnExit
			>
				<CustomAccordionDetails>
					{property_category.active_options.map((item: SearchFilterOptionTextActive) => (
						<CheckBoxDiv
							key={item.id}
							onClick={() => {
								item.is_selected
									? handleUpdatePropertyCategory([] as Array<SearchFilterOptionTextActive>)
									: handleUpdatePropertyCategory([item] as Array<SearchFilterOptionTextActive>);
							}}
							sx={{
								background: item.is_selected ? "#FC801933" : "",
								border: item.is_selected
									? "1px solid var(--Other-New-Outline-Border, rgba(255, 255, 255, 0.23))"
									: "1px solid #C0C0C0",
							}}
						>
							{item.is_selected ? (
								<DoneIcon sx={{ color: theme.palette.mode === "dark" ? "#ffffff" : "#623816" }} />
							) : (
								<AddIcon sx={{ color: theme.palette.mode === "dark" ? "#ffffff" : "#623816" }} />
							)}
							<Text variant="body1">{item.title}</Text>
						</CheckBoxDiv>
					))}
				</CustomAccordionDetails>
			</Slide>
		</CustomAccordion>
	);
};
