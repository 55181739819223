const prepareConfigTitle = (transaction_type: string, property_type: Array<string>) => {
	if (property_type !== undefined) {
		if (property_type.includes("Studio Apartment")) {
			/* Studio Apartment */

			return "Bedrooms";
		} else if (property_type.includes("Co-living/PG")) {
			/* Co-living */

			if (transaction_type === "rent") {
				return "Rooms";
			} else {
				return "Rooms";
			}
		} else if (property_type.includes("Building")) {
			/* Building */

			return "No of floors";
		} else if (
			property_type.includes("Plot") ||
			property_type.includes("Farm Plot") ||
			property_type.includes("Land (Residential)") ||
			property_type.includes("Land (Commercial)") ||
			property_type.includes("Land (Agricultural)") ||
			property_type.includes("Land (Industrial)") ||
			property_type.includes("Office Space") ||
			property_type.includes("Factory") ||
			property_type.includes("Mining") ||
			property_type.includes("Warehouse")
		) {
			/* Plots, Lands, Office space, Factory, Mining, Warehouse */

			if (transaction_type === "rent") {
				return "Price/sqft";
			} else {
				return "Price/sqft";
			}
		} else if (property_type.includes("Co-working")) {
			/* Co-working */

			if (transaction_type === "rent") {
				return "No of seats";
			} else {
				return "No of seats";
			}
		} else if (
			property_type.includes("Apartment") ||
			property_type.includes("Independent House") ||
			property_type.includes("Villa") ||
			property_type.includes("Row House") ||
			property_type.includes("Builder Floor Apartment") ||
			property_type.includes("Penthouse") ||
			property_type.includes("Farm House")
		) {
			/* Apartments, Independent House, Villa, Row House, Builder Floor Apartment, Penthouse, Farm House */

			return "Bedrooms";
		}
	}
};

export default prepareConfigTitle;
