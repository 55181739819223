/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Redux Imports */

import StateInterface from "../../../../../redux-magic/state-interface";
import { addSaveEntityBusinessProfileThunk } from "../../../../../redux-magic/store";
import { ObjectId } from "mongodb";
import ShareModal from "../../../../modals/ShareModal";

/* Component Imports */

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { IconButton, Menu, MenuItem, Skeleton, Tooltip, useTheme, Paper, Box, Typography } from "@mui/material";
import { useRouter } from "next/router";
import DeleteListingPropertyModal from "../../../../modals/PropertyModal/DeleteListingProperty";

/* Icon Imports */

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkOutlinedIcon from "@mui/icons-material/BookmarkOutlined";
import { MoreVertOutlined } from "@mui/icons-material";
import { signIn } from "next-auth/react";
import BoltIcon from "@mui/icons-material/Bolt";

/* Styled Components */

const Container = styled("div")(({ theme }) => ({
	objectFit: "cover",
	width: "100%",
	height: "12.063rem",
	borderRadius: "16px 16px 0px 0px",
	position: "relative",
}));

const Image = styled("img")(({ theme }) => ({
	display: "block",
	objectFit: "cover",
	width: "100%",
	height: "12.063rem",
	borderRadius: "16px 16px 0px 0px",
}));

const BookmarkIconButton = styled(IconButton)(({ theme }) => ({
	background: "#E6DACF",
	padding: "0.25rem",
	width: "2rem",
	height: "2rem",
	borderRadius: "12px",
	"&:hover": {
		background: "#E6DACF",
	},
}));
const BoltContainer = styled("div")(({ theme }) => ({
	background: "white",
	"&:hover": {
		background: "white",
	},
}));

const ThreeDotIconButton = styled(IconButton)(({ theme }) => ({
	background: "#00000040",
	margin: "0.5rem 0.5rem 0rem 0rem",
	"&:hover": {
		background: "#00000040",
	},
}));

const CarouselComp = styled(Carousel)(({ theme }) => ({
	"& .carousel .control-dots .dot": {
		display: "none",
		backgroundColor: "#FFFFFF",
		opacity: 1,
	},
	"& .carousel .control-arrow, .carousel.carousel-slider .control-arrow": {
		margin: "0.5rem",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		top: "45%",
		backgroundColor: theme.palette.primary.main,
		borderRadius: "5rem",
		opacity: 1,
		height: "2rem",
		width: "2rem",
	},
	"& .carousel .control-dots .dot.selected": {
		backgroundColor: theme.palette.primary.main,
	},
}));

const BackArrow = styled(ArrowBackIosNewIcon)(({ theme }) => ({
	position: "absolute",
	width: "25px",
	height: "25px",
	zIndex: 1,
	top: "45%",
	color: theme.palette.primary.main,
	[theme.breakpoints.down("lg")]: {
		display: "none",
	},
}));

const ForwardArrow = styled(ArrowForwardIosIcon)(({ theme }) => ({
	position: "absolute",
	width: "25px",
	height: "25px",
	zIndex: 1,
	top: "45%",
	right: "0",
	color: theme.palette.primary.main,
	[theme.breakpoints.down("lg")]: {
		display: "none",
	},
}));

/*
setting a constant image for when the image is not available or something goes wrong
*/

const fallbackImage: string = process.env.CDN_URL + "images/uploads/property.jpg";

const GridImage = ({
	_id,
	slug,
	images,
	dispatch,
	profile_context,
	session,
	loading,
	saved_content,
	page_type,
	created_by,
	assigned_to,
	isEditor,
	boosting,
	preview,
}: {
	_id: ObjectId | string;
	slug: string;
	images: string;
	dispatch: Function;
	profile_context: StateInterface["profile_context"];
	session: any;
	loading: boolean;
	saved_content: Array<any>;
	page_type?: string;
	created_by?: any;
	assigned_to?: any;
	isEditor?: boolean;
	boosting?: any;
	preview?: boolean;
}) => {
	/*
		
		* Share Modal State
		
	*/

	const [shareModal, setShareModal] = React.useState(false);

	/* 
	
		* Share Modal open and close functions
	
	*/

	const handleShareModalOpen = () => {
		setShareModal(true);
	};

	const handleShareModalClose = () => {
		setShareModal(false);
	};

	const theme = useTheme();

	/*
  check if page is loaded. i loaded set to true. if page loads we use regular image. if not we use lqip image.
  */
	const [isPageLoaded, SetIsPageLoaded] = React.useState<boolean>(false);

	React.useEffect(() => {
		SetIsPageLoaded(true);
	}, [loading]);

	/* Property card carousel using pathname to check */

	const router = useRouter();

	const pathname = React.useRef(router).current;

	/*

	& Next, Let's define a local state to handle menu.

	*/

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	/*

  & Next, Let's define a constant to hold anchorEl state.

  */

	const [open, setOpen] = React.useState(false);
	const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean>(false);

	const handleCloseDeleteModal = () => {
		setOpenDeleteModal(false);
	};

	/*

  & Next, Let's descride a function to chnage anchorEl state.

  */

	const handleClick = (event: any) => {
		event.preventDefault();
		setAnchorEl(event.currentTarget);
		setOpen(true);
	};

	/*

  & Next, let's declare  local state to handle bookmark.

  */

	const [bookmark, setBookmark] = React.useState(
		saved_content?.find((item) => {
			return profile_context && profile_context.is_business_profile
				? item.saved_by.business_profile_id ===
						(item.saved_by.is_business_profile ? profile_context?.business_profile_id : "")
				: item.saved_by.user_id === (item.saved_by.is_business_profile === false ? profile_context?.user_id : "");
		}) !== undefined,
	);

	React.useEffect(() => {
		setBookmark(
			saved_content?.find((item) => {
				return profile_context && profile_context.is_business_profile
					? item.saved_by.business_profile_id ===
							(item.saved_by.is_business_profile ? profile_context?.business_profile_id : "")
					: item.saved_by.user_id === (item.saved_by.is_business_profile === false ? profile_context?.user_id : "");
			}) !== undefined,
		);
	}, [profile_context, saved_content]);

	const submit = async () => {
		if (session === null) {
			window.open("/auth/login");
		} else {
			await dispatch(
				addSaveEntityBusinessProfileThunk({
					is_business_profile: profile_context?.is_business_profile,
					user_id: profile_context?.user_id,
					business_profile_id: profile_context?.business_profile_id,
					entity_id: _id,
					entity_type: "property",
					add_remove: !bookmark ? "add" : "remove",
					url: pathname.asPath ? pathname.asPath : "",
				}),
			);
			setBookmark(!bookmark);
		}
	};

	const handleDelete = async () => {
		setOpenDeleteModal(true);
		setAnchorEl(null);
		setOpen(false);
	};
	const handleClose = () => {
		setAnchorEl(null);
		setOpen(false);
	};
	const [isHovered, setHovered] = React.useState(false);

	if (loading) {
		return (
			<React.Fragment>
				<Skeleton
					animation="wave"
					variant="rectangular"
					width="100%"
					height="100%"
					style={{ borderRadius: "16px", margin: "0rem" }}
				/>
			</React.Fragment>
		);
	}
	let BoostingCheck = boosting?.active ? true : false;

	/* Boosting end date */

	const date = new Date(boosting?.ends_at);

	/* Date , Month and year format*/

	const formattedDate = `
  			${date.getDate()} 
  			${date.toLocaleString("en-US", {
					month: "short",
				})} 
  			${date.getFullYear().toString().slice(2)}`;

	const BoostPaper = (
		<Paper
			sx={{
				position: "absolute",
				top: 13,
				left: 13,
				zIndex: 4,
				display: "flex",
				justifyContent: "center",
				alignItems: "space-between",
				height: "2.25rem",
				padding: "0.43rem",
				flexDirection: "column",
				overflow: "hidden",
				borderRadius: "16px",
				boxShadow: "none",
				backgroundColor: "white",
			}}
		>
			<BoltContainer
				// onClick={(event: any) => {
				//   event.preventDefault();
				//   session === null ? window.open("/auth/login") : submit();
				// }}
				sx={{
					display: "flex",
					width: !isHovered ? "1.5rem" : "11rem",
					height: "2rem",
					gap: "0.5rem",
					overflow: "hidden",
					borderRadius: "16px",
					transition: "width 0.25s ease-in-out",
				}}
			>
				<BoltIcon
					sx={{ color: theme.palette.primary.main }}
					fontSize="medium"
				/>
				<Typography
					sx={{
						color: "black",
					}}
				>
					{session === null ? "Boosted Property" : "Ends on " + formattedDate}
				</Typography>
			</BoltContainer>
		</Paper>
	);

	return (
		<React.Fragment>
			{/* Share Modal */}

			<ShareModal
				open={shareModal}
				handleClose={handleShareModalClose}
				url={process.env.PRODUCTION_URL + "properties/" + slug}
				session={session}
				dispatch={dispatch}
			/>

			{/* Delete Modal */}

			<DeleteListingPropertyModal
				open={openDeleteModal}
				handleClose={handleCloseDeleteModal}
				creator_user_id={created_by?.userId}
				creator_business_profile_id={created_by?.business_profile_id}
				propertyId={_id}
				session={session}
				isEditor={isEditor ? isEditor : false}
				profile_context={profile_context}
				dispatch={dispatch}
				property_type={"property"}
			/>

			<Container
				sx={{
					borderTop: BoostingCheck ? "3px solid #fdb375" : "none",
					borderRight: BoostingCheck ? "3px solid #fdb375" : "none",
					borderLeft: BoostingCheck ? "3px solid #fdb375" : "none",
					// boxShadow: BoostingCheck ? "none" : "0px 1px 3px rgba(0, 0, 0, 0.12)",
				}}
			>
				{/* Bookmark Icon */}

				{preview ? null : (
					<>
						{/* <BookmarkIconButton
							onClick={(event: any) => {
								event.preventDefault();
								// session === null ? window.open("/auth/login") : submit();
							}}
							sx={{
								position: "absolute",
								top: 12,
								right: page_type ? 48 : 12,
								zIndex: 4,
							}}
						>
							<Tooltip title="Share">
								<ShareOutlinedIcon
									sx={{ color: "#623816" }}
									fontSize="small"
									onClick={(event: any) => {
										handleShareModalOpen();
									}}
								/>
							</Tooltip>
						</BookmarkIconButton> */}

						{session !== null ? (
							<BookmarkIconButton
								onClick={(event: any) => {
									event.preventDefault();
									session === null ? window.open("/auth/login") : submit();
								}}
								sx={{
									position: "absolute",
									top: 12,
									//change it to 53 after fixing share og
									right: page_type ? 48 : 12,
									zIndex: 4,
								}}
							>
								<Tooltip title="Favourite">
									{bookmark == false ? (
										<BookmarkBorderOutlinedIcon
											sx={{ color: "#623816" }}
											fontSize="small"
										/>
									) : (
										<BookmarkOutlinedIcon
											sx={{ color: "#623816" }}
											fontSize="small"
										/>
									)}
								</Tooltip>
							</BookmarkIconButton>
						) : null}
					</>
				)}

				{boosting?.active === true ? (
					<div
						onMouseEnter={() => setHovered(true)}
						onMouseLeave={() => setHovered(false)}
						style={{
							position: "absolute",

							[theme.breakpoints.down("sm")]: {
								display: "none",
							},
						}}
					>
						<Box sx={{ width: "100%" }}>
							<div>{BoostPaper}</div>
						</Box>
					</div>
				) : null}

				{page_type === "profile" ? (
					<ThreeDotIconButton
						onClick={(event: any) => {
							event.preventDefault();
							session === null ? signIn() : handleClick(event);
						}}
						sx={{
							position: "absolute",
							top: 12,
							right: 3,
							zIndex: 4,
						}}
					>
						<MoreVertOutlined sx={{ color: "#fafafa" }} />
					</ThreeDotIconButton>
				) : null}

				<Menu
					id="basic-menu"
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					transformOrigin={{ horizontal: "right", vertical: "top" }}
					anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
					disableScrollLock={true}
				>
					{session?.user?.dbUser?._id === created_by?.user_id ||
					session?.user?.dbUser?.access_level.includes("super_admin") ||
					(session?.user?.dbUser?.access_level.includes("admin") &&
						session?.user?.dbUser?.access_level.includes("business_profiles") &&
						assigned_to?.includes(session?.user?.dbUser?._id)) ? (
						<MenuItem
							onClick={() => {
								window.open(`/properties/${slug}`, "_self");
								handleClose();
							}}
						>
							Edit property
						</MenuItem>
					) : null}

					{session?.user?.dbUser?._id === created_by?.user_id ||
					session?.user?.dbUser?.access_level.includes("super_admin") ||
					(session?.user?.dbUser?.access_level.includes("admin") &&
						session?.user?.dbUser?.access_level.includes("business_profiles") &&
						assigned_to?.includes(session?.user?.dbUser?._id)) ? (
						<MenuItem onClick={handleDelete}>Delete property</MenuItem>
					) : null}

					{/* <MenuItem>
						<Link
							href={`/boostings/property/${slug}`}
							style={{
								textDecoration: "none",
								color: "inherit",
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-start",
								alignItems: "center",
							}}
							onClick={handleClose}
							rel="noopener"
							target="_blank"
							referrerPolicy="no-referrer"
							component="a"
						>
							<ListItemIcon>
								<img
									src="/images/icons/business-profile/tunder.svg"
									width={22}
									height={22}
									alt="tunder"
									referrerPolicy="no-referrer"
								/>
							</ListItemIcon>
							Boost property
						</Link>
					</MenuItem> */}
				</Menu>

				{pathname.asPath === "/" || images.length === 0 ? (
					<Image
						sx={{
							objectFit: images[0].includes("images/uploads/placeholder_1080p_old.svg") ? "fill" : "cover",
						}}
						src={images ? (images.length > 0 ? (isPageLoaded ? images[0] : images[0]) : fallbackImage) : ""}
						alt="No Images Available"
						referrerPolicy="no-referrer"
					/>
				) : (
					<CarouselComp
						axis="horizontal"
						autoPlay={false}
						showArrows={true}
						showThumbs={false}
						swipeable={true}
						showStatus={false}
						infiniteLoop={false}
						emulateTouch={true}
						preventMovementUntilSwipeScrollTolerance={true}
						swipeScrollTolerance={8}
						renderArrowPrev={(clickHandler: any, hasPrev: any) =>
							hasPrev && (
								<Tooltip title="Previous Image">
									<BackArrow
										onClick={(event: any) => {
											event.preventDefault();
											clickHandler();
										}}
									/>
								</Tooltip>
							)
						}
						renderArrowNext={(clickHandler: any, hasNext: any) =>
							hasNext && (
								<Tooltip title="Next Image">
									<ForwardArrow
										onClick={(event: any) => {
											event.preventDefault();
											clickHandler();
										}}
									/>
								</Tooltip>
							)
						}
					>
						{/* <Image
							src={images ? (images.length > 0 ? (isPageLoaded ? images[0] : images[0]) : fallbackImage) : ""}
							alt="No Images Available"
							referrerPolicy="no-referrer"
						/> */}
					</CarouselComp>
				)}
			</Container>
		</React.Fragment>
	);
};

export default GridImage;
