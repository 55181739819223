import React from "react";
import { styled } from "@mui/system";

import { Paper, Skeleton, useTheme } from "@mui/material";

/* Styled Components */

const MainContainer = styled(Paper)(({ theme }) => ({
	background: theme.palette.background.paper,
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "16px",
	objectFit: "contain",
	width: "100%",
	height: "18.25rem",
	boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
	padding: "1.25rem",
	gap: "1.5rem",
	[theme.breakpoints.down("sm")]: { display: "none" },
	/*ipad Mini */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		width: "42rem",
	},
	/* ipad Air*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
		width: "45rem",
	},
	[theme.breakpoints.up("sm822")]: {
		width: "47rem",
	},
	[theme.breakpoints.up("sm910")]: {
		width: "51rem",
	},
	[theme.breakpoints.up("md")]: {
		width: "55.5rem",
	},
	/*720p and 768p breakpoint */
	[theme.breakpoints.up("lg")]: {
		width: "54rem",
	},
	/* 1080p 125% breakpoint*/
	[theme.breakpoints.up(theme.breakpoints.values.lg + 150)]: {
		width: "64rem",
	},
	/* 1080p breakpoint*/
	[theme.breakpoints.up("xl")]: {
		width: "55rem",
	},
	/* XXL breakpoint  2560p*/
	[theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
		width: "65.5rem",
	},
	/*4k breakpoint 3840p*/
	[theme.breakpoints.up(theme.breakpoints.values.xl + 1920)]: {
		width: "71rem",
	},
}));

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
	width: "60%",
}));

const PriceDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	width: "100%",
	gap: "0.5rem",
}));

const BookmarkDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-end",
	alignItems: "center",
	width: "35%",
}));

const ChipDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	padding: "0.5rem 0rem 0.5rem 0rem",
	width: "100%",
	gap: "1rem",
}));

const ButtonDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-end",
	alignItems: "flex-end",
	width: "100%",
	padding: "0.5rem 0rem 0rem 0rem",
}));

const PropertyListLoadingCard = () => {
	const theme = useTheme();
	return (
		<React.Fragment>
			<MainContainer>
				<Skeleton
					animation="wave"
					variant="rectangular"
					width="23rem"
					height= "15.75rem"
					style={{ borderRadius: "4px" }}
				/>
				<Container>
					<PriceDiv>
						<Skeleton
							animation="wave"
							variant="text"
							width="30%"
							height="1.5rem"
						/>
						<Skeleton
							animation="wave"
							variant="text"
							width="30%"
							height="1.5rem"
						/>
						<BookmarkDiv>
							<Skeleton
								animation="wave"
								variant="circular"
								width="18%"
								height="2rem"
							/>
						</BookmarkDiv>
					</PriceDiv>
					<Skeleton
						animation="wave"
						variant="text"
						width="70%"
						height="1.5rem"
					/>
					<PriceDiv>
						<Skeleton
							animation="wave"
							variant="text"
							width="30%"
							height="1.5rem"
						/>
						<Skeleton
							animation="wave"
							variant="text"
							width="30%"
							height="1.5rem"
						/>
					</PriceDiv>
					<PriceDiv style={{ padding: "0.5rem 0rem 0rem 0rem" }}>
						<Skeleton
							animation="wave"
							variant="text"
							width="20%"
							height="1.5rem"
						/>
						<Skeleton
							animation="wave"
							variant="text"
							width="20%"
							height="1.5rem"
						/>
					</PriceDiv>
					<PriceDiv>
						<Skeleton
							animation="wave"
							variant="text"
							width="20%"
							height="1.5rem"
						/>
						<Skeleton
							animation="wave"
							variant="text"
							width="20%"
							height="1.5rem"
						/>
					</PriceDiv>
					<ChipDiv>
						<Skeleton
							animation="wave"
							variant="text"
							width="20%"
							height="2rem"
						/>
						<Skeleton
							animation="wave"
							variant="text"
							width="20%"
							height="2rem"
						/>
						<Skeleton
							animation="wave"
							variant="text"
							width="20%"
							height="2rem"
						/>
						<Skeleton
							animation="wave"
							variant="text"
							width="20%"
							height="2rem"
						/>
					</ChipDiv>
					<Skeleton
						animation="wave"
						variant="text"
						width="100%"
						height="auto"
					/>
					<ButtonDiv>
						<Skeleton
							animation="wave"
							variant="rectangular"
							width="40%"
							height="2rem"
							style={{ borderRadius: "4px" }}
						/>
					</ButtonDiv>
				</Container>
			</MainContainer>
		</React.Fragment>
	);
};

export default PropertyListLoadingCard;
