/*

? First, let's import the required components.

*/
import React from "react";
import { styled, useTheme } from "@mui/system";
import { MenuItem, FormControl, Select, SelectChangeEvent } from "@mui/material";
import LocationAutoComplete from "./SearchComponent/LocationAutoComplete";
import BeegruButton from "../../common-components/buttons/BeegruButton";
import StateInterface from "../../../redux-magic/state-interface";
import {
	setSearchAnimationDetailsThunk,
	updateSearchFilterAppliedThunk,
	updateSearchFilterSelectedOptionsThunk,
	setSearchFilterMetricesThunk,
	updateSearchFilterVisibilityThunk,
} from "../../../redux-magic/thunks";
/*

? Next let's import all the UI sub-components we intend to use on this page.

*/

/*

? Next, let's import the select change event signature.

*/

/*

& Let's define a custom attribute for our container called index. This will help the layout container organise our component correctly.

*/

type IndexAttribute = {
	index?: number;
};

/*

& Now let's style all the components we intend to use on this page.

*/

const SearchContainer = styled("div")<IndexAttribute>(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	justifyContent: "flex-start",
	width: "100%",
	height: "100%",
	padding: "1rem",
	backgroundColor: theme.palette.background.paper,
	borderRadius: "8px",
	boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.12)",
	[theme.breakpoints.down("md1220")]: {
		padding: "0.65rem 1rem 0.65rem 1rem",
	},
	[theme.breakpoints.down("sm")]: {
		display: "none",
	},
}));

const VerticalLine = styled("div")(({ theme }) => ({
	borderLeft: "1px solid #D9D9D9",
	height: "2rem",
	paddingLeft: "0.5rem",
	paddingRight: "0.5rem",
	[theme.breakpoints.down("md1220")]: {
		display: "none",
	},
}));

/*

& Next, let's create the component.

*/

const SearchAfterAnimationComponent = ({
	dispatch,
	search_filter_state,
	searchanimationsettings,
}: {
	dispatch: Function;
	search_filter_state: StateInterface["search_filters_state"];
	searchanimationsettings: StateInterface["search_animation_settings"];
}) => {
	const theme = useTheme();
	// console.log(search_filter_state, "search_filter_state");
	const [value, setValue] = React.useState(
		search_filter_state.search_type.selected_options[0].id === "properties"
			? search_filter_state.transaction_type.selected_options[0].id === "buy"
				? "Buy"
				: search_filter_state.transaction_type.selected_options[0].id === "rent"
					? "Rent"
					: "Open For Business"
			: search_filter_state.search_type.selected_options[0].id === "services"
				? "Services"
				: "Business Users",
	);

	const handleChangeBusinessUsers = (value: string) => {
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_category", isApplied: false }));
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_type", isApplied: false }));

		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_category", isVisible: false }));
		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_type", isVisible: false }));
		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_status", isVisible: false }));

		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_category", isApplied: false }));
		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_category", isVisible: false }));
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_subtype", isApplied: false }));

		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_subtype", isVisible: false }));
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_type", isApplied: false }));
		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_type", isVisible: false }));
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: "transaction_type",
				newOptions: [],
			}),
		);
		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: "transaction_type",
				isApplied: false,
			}),
		);
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: "search_type",
				newOptions: [
					{
						id: value.toLocaleLowerCase(),
						title: value,
						value: value.toLocaleLowerCase(),
					},
				],
			}),
		);
		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: "search_type",
				isApplied: true,
			}),
		);
	};

	const handleChange = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
		const newValue = event.target.value;
		setValue(newValue);

		if (newValue === "Buy" || newValue === "Open For Business" || newValue === "Rent") {
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "transaction_type",
					newOptions: [
						{
							id: newValue === "Open For Business" ? "ofb" : newValue.toLowerCase(),
							title: newValue,
							value: newValue === "Open For Business" ? "ofb" : newValue.toLowerCase(),
						},
					],
				}),
			);
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: "transaction_type",
					isApplied: true,
				}),
			);
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "search_type",
					newOptions: [
						{
							id: "properties",
							title: "Properties",
							value: "properties",
						},
					],
				}),
			);
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: "search_type",
					isApplied: true,
				}),
			);
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_category", isApplied: false }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_type", isVisible: false }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_category", isVisible: true }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_type", isVisible: true }));
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_category", isApplied: true }));
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_type", isApplied: true }));
			if (newValue === "Rent") {
				dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "security_deposit", isVisible: true }));
			}
		} else if (newValue === "Services") {
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "transaction_type",
					newOptions: [],
				}),
			);
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: "transaction_type",
					isApplied: false,
				}),
			);
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "search_type",
					newOptions: [
						{
							id: newValue.toLowerCase(),
							title: newValue,
							value: newValue.toLowerCase(),
						},
					],
				}),
			);
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: "search_type",
					isApplied: true,
				}),
			);
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_category", isApplied: true }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_type", isVisible: true }));
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_category", isApplied: false }));
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_type", isApplied: false }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_category", isVisible: false }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_type", isVisible: false }));
		} else if (newValue === "Business Users") {
			handleChangeBusinessUsers("Agents");
		}
	};
	const [animationComplete, setAnimationComplete] = React.useState(false);

	// React.useEffect(() => {
	// 	if (searchanimationsettings.StartAnimate) {
	// 		// Assuming the animation duration is 1 second (1000 ms)
	// 		const timer = setTimeout(() => {
	// 			setAnimationComplete(true);
	// 		}, 250);

	// 		return () => clearTimeout(timer);
	// 	} else {
	// 		setAnimationComplete(false);
	// 	}
	// }, [searchanimationsettings.StartAnimate]);

	return (
		<React.Fragment>
			<SearchContainer>
				<FormControl
					sx={{
						width: "15%",
						boxShadow: "none",
						".MuiOutlinedInput-notchedOutline": { border: 0 },
						"&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
							border: 0,
						},
						"&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
							border: 0,
						},
						[theme.breakpoints.down("md1220")]: {
							display: "none",
						},
					}}
				>
					<Select
						value={value}
						onChange={handleChange}
						inputProps={{ "aria-label": "Without label" }}
					>
						<MenuItem value={"Buy"}>Buy</MenuItem>
						<MenuItem value={"Rent"}>Rent</MenuItem>
						<MenuItem value={"Open For Business"}>Open For Business</MenuItem>
						<MenuItem value={"Services"}>Services</MenuItem>
						<MenuItem value={"Business Users"}>Business Users</MenuItem>
					</Select>
				</FormControl>
				<VerticalLine />
				<LocationAutoComplete
					search_id="location"
					dispatch={dispatch}
					search_filter_state={search_filter_state.location}
				/>
				<BeegruButton
					flavor="primary"
					variant="contained"
					size="large"
					onClick={() => {
						dispatch(
							setSearchFilterMetricesThunk({
								boosted: [0],
								non_boosted: [0],
								filter_hash: "",
								no_of_boosted_results: 0,
								no_of_non_boosted_results: 0,
								total_no_of_results: 0,
							}),
						);
						dispatch(
							setSearchAnimationDetailsThunk({
								StartAnimate: searchanimationsettings.StartAnimate,
								TriggerSearch: true,
								ResetSearch: true,
								PropertySubCategory: searchanimationsettings.PropertySubCategory,
								HomeAccordion: searchanimationsettings.HomeAccordion,
							}),
						);
					}}
				>
					Search
				</BeegruButton>
			</SearchContainer>
		</React.Fragment>
	);
};

export default SearchAfterAnimationComponent;
