/* Common Imports */

import { styled } from "@mui/system";
import React from "react";

/* Redux Imports */

import { Price } from "../../../../redux-magic/sub-interfaces/sub-interfaces";

/* Component Imports */

import { Typography } from "@mui/material";

/* Icon Imports */

import { CurrencyRupee } from "@mui/icons-material";

/* Library Function Imports */

import PreparePriceString from "../../../../lib/PreparePriceString";

/* Styled Components */

const Heading = styled(Typography)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	fontWeight: 500,
	fontSize: "1.25rem",
	lineHeight: "1.5rem",
	letterSpacing: "0.009rem",
	margin: "0rem 0rem 0rem 0rem",
}));

const RupeeIcon = styled(CurrencyRupee)(({ theme }) => ({
	margin: "0.15rem 0rem 0rem 0rem",
	fontSize: "1.25rem",
}));

const PropertyListCardPrice = ({ price, loading }: { price: Price; loading: boolean }) => {
	return (
		<Heading>
			<RupeeIcon />
			{price
				? price.min === price.max
					? PreparePriceString(price.max)
					: PreparePriceString(price.min) + " - " + PreparePriceString(price.max)
				: "Price not available"}
		</Heading>
	);
};

export default PropertyListCardPrice;
