/*

? First, let's import the essentials - React, Layout, styled and Theme.

*/

import React from "react";
import { styled } from "@mui/system";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import { Skeleton, Typography, useTheme } from "@mui/material";
import prepareAreaString from "../../../../lib/PrepareAreaString";
import { Area } from "../../../../redux-magic/sub-interfaces/property-interface";

/*
 
& Next, let's style all the components we intend to use on this page.

*/

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-end",
	alignItems: "flex-start",
	width: "100%",
	height: "100%",
	margin: "0rem 0.2rem 0rem 0rem",
}));

const SkeletonSection = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
}));

const PropertyListCardArea = ({ loading, area, areaType }: { loading: boolean; area: Area; areaType: string }) => {
	const theme = useTheme();

	if (loading) {
		return (
			<SkeletonSection>
				<Skeleton
					animation="wave"
					variant="text"
					width="30%"
					height="auto"
					style={{ marginBottom: "0.5rem" }}
				/>
			</SkeletonSection>
		);
	}

	return (
		<Container>
			<Typography variant="body1">{prepareAreaString(area, areaType)}</Typography>
			<Typography
				variant="body2"
				color={theme.palette.text.secondary}
			>
				Area
			</Typography>
		</Container>
	);
};

export default PropertyListCardArea;
