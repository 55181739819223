/* Common Imports */

import React from "react";
import { styled, useTheme } from "@mui/system";

/* Redux Imports */

import { ObjectId } from "mongodb";

/* Component Imports */

import { Typography, Link, Skeleton, Tooltip, SvgIcon } from "@mui/material";
import PremiumBadge from "../../../public/images/badges/premium.svg";
import VerifiedPremiumBadge from "../../../public/images/badges/verified_premium.svg";
import Initials from "../Initials";
import BeegruButton from "../../common-components/buttons/BeegruButton";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";

/* Styled Components */

const BusinessProfileCardLink = styled(Link)(({ theme }) => ({
	textDecoration: "none",
	color: theme.palette.mode == "dark" ? "#fff" : "#000",
	"&:focus, &:hover, &:visited, &:link, &:active": {
		textDecoration: "none",
	},
}));

const PaperContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	// justifyContent: "flex-start",
	// alignItems: "flex-start",
	background: theme.palette.background.paper,
	width: "100%",
	height: "auto",
	padding: "1.5rem",
	boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
	borderRadius: "1rem",
	gap: "2rem",
	[theme.breakpoints.down("sm")]: {
		width: "100%",
	},
}));
const Image = styled("img")(({ theme }) => ({
	// display: "flex",
	height: "100%",
	width: "100%",
	// justifyContent: "center",
	// alignContent: "center",
	borderRadius: "8px",
	objectFit: "cover",
	[theme.breakpoints.down("sm")]: {
		height: "4.5rem",
		width: "4.5rem",
	},
}));

const ImageDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	gap: "1.5rem",
	height: "100%",
	width: "100%",
}));

const InfoDiv = styled("div")(({ theme }) => ({
	display: "flex",
	justifyContent: "flex-start",
	color: theme.palette.text.primary,
}));

const TypographyDiv = styled(Typography)(({ theme }) => ({
	color: theme.palette.text.primary,
	textTransform: "capitalize",
	gap: "1rem",
}));

const BusinessProfileMobileCard = ({ _id }: { _id: ObjectId | string }) => {
	/*
  
  * Checks if display_picture.exists true
  
  */
	// const theme = useTheme();

	// const Checker = display_picture ? (display_picture.exists ?? false) : false;

	// const ProfileId = display_picture ? (display_picture?.file_id ? display_picture.file_id.toString() : "") : "";

	// const profilePicture = Checker ? `/profile-picture/${ProfileId}` : "/images/icons/Avatar.svg";

	// const phoneNumber =
	// 	phone?.phone_number === " "
	// 		? created_by
	// 			? created_by.country_code
	// 				? created_by.country_code + " " + created_by.phone
	// 				: " "
	// 			: " "
	// 		: phone
	// 			? phone.country_code + " " + phone.phone_number
	// 			: " ";

	const [businessData, setBusinessData] = React.useState<any>({});
	const [loading, setLoading] = React.useState(true);

	React.useEffect(() => {
		if (_id) {
			fetch(`${process.env.PRODUCTION_API_URL}business-profiles/cards/${_id}`, {
				method: "POST",
				headers: {
					"Content-Type": "text/plain",
				},
			})
				.then((res) => res.json())
				.then((data) => {
					// console.log(data, "data");
					// console.log(data.BusinessProfileData, "data");
					setBusinessData(data.BusinessProfileData);
					setLoading(false);
				});
		}
	}, [_id]);

	console.log(businessData, "businessData");
	if (loading) {
		// Skeleton loading state
		return (
			<React.Fragment>
				<PaperContainer>
					<ImageDiv>
						<Skeleton
							variant="rectangular"
							width={100}
							height={100}
							animation="wave"
						/>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								gap: "1.5rem",
								width: "80%",
							}}
						>
							<div>
								<div
									style={{
										display: "flex",
										gap: "1rem",
										alignItems: "center",
									}}
								>
									<Skeleton
										variant="text"
										width="70%"
										height="2rem"
										animation="wave"
									/>
									<Skeleton
										variant="circular"
										width={20}
										height={20}
										animation="wave"
									/>
								</div>
								<Skeleton
									variant="text"
									width="50%"
									height="1.5rem"
									animation="wave"
								/>
							</div>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									background: "#FAFAFA",
									borderRadius: "8px",
									padding: "0.5rem 1rem",
									gap: "1.5rem",
								}}
							>
								<Skeleton
									variant="text"
									width="30%"
									height="1rem"
									animation="wave"
								/>
								<Skeleton
									variant="text"
									width="50%"
									height="1rem"
									animation="wave"
								/>
							</div>
						</div>
					</ImageDiv>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "flex-end",
						}}
					>
						<Skeleton
							variant="rectangular"
							width="8rem"
							height="2.5rem"
							animation="wave"
						/>
					</div>
				</PaperContainer>
			</React.Fragment>
		);
	} else {
		return (
			<React.Fragment>
				<PaperContainer>
					<ImageDiv>
						{!businessData.cover_image?.includes("") ? (
							<Image
								alt="Popular User"
								loading="lazy"
								referrerPolicy="no-referrer"
								// height={512}
								// width={512}
								src={businessData.cover_image}
							/>
						) : (
							<Initials
								title={businessData.title}
								profiledropdown={false}
								header={false}
								businessprofile={false}
								postcard={false}
								comment={false}
								search={true}
							/>
						)}
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								gap: "0.5rem",
								width: "80%",
							}}
						>
							<div>
								<div
									style={{
										display: "flex",
										gap: "1rem",
										alignItems: "center",
									}}
								>
									<TypographyDiv variant="body1">{businessData.title}</TypographyDiv>
									{businessData.subscription_information?.active ? <PremiumBadge /> : null}
								</div>
								<TypographyDiv variant="body2"> {businessData.business_profile_type}</TypographyDiv>
							</div>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									// alignContent: "flex-end",
									background: "#FAFAFA",
									borderRadius: "8px",
									padding: "0.35rem",
									gap: "0.25rem",
								}}
							>
								<InfoDiv>{businessData.listingCount}</InfoDiv>
								<InfoDiv>
									{businessData.business_profile_type === "professional"
										? "Services"
										: businessData.business_profile_type === "developer"
											? "Builder"
											: "Properties"}
								</InfoDiv>
							</div>
						</div>
					</ImageDiv>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "flex-end",
						}}
					>
						<BeegruButton
							variant="outlined"
							color="primary"
							size="medium"
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								borderRadius: "1rem",
								fontSize: "0.875rem",
								lineHeight: "1rem",
								letterSpacing: "0.025rem",
								fontWeight: 400,
								width: "100%",
								height: "2.5rem",
								gap: "0.5rem",
								zIndex: 4,
							}}
							href={
								businessData &&
								businessData.phone &&
								`tel:${businessData.phone.country_code} ${businessData.phone.phone_number}`
							}
						>
							<SvgIcon
								component={LocalPhoneOutlinedIcon}
								inheritViewBox={false}
								sx={{
									display: "flex",
									justifyContent: "flex-end",
									color: "#1CA672",
									fontSize: "1.1rem",
								}}
							/>
							Call now
						</BeegruButton>
					</div>
				</PaperContainer>
			</React.Fragment>
		);
	}
};

export default BusinessProfileMobileCard;
