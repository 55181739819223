/* Common Imports */

import React from "react";

/* Component Imports */

import ServiceCard from "./ServiceCardGridView";
import ServiceLoadingCard from "../skeletonLoadingCard/ServiceLoadingCard";

/* Styled Components */

const ServiceCardFetcher = ({
	ServiceId,
	dispatch,
	session,
	profile_context,
	preview,
	source,
}: {
	ServiceId: any;
	dispatch: any;
	session: any;
	profile_context: any;
	preview?: any;
	source?: string;
}) => {
	const [serviceData, setServiceData] = React.useState<any>({});
	const [loading, setLoading] = React.useState(true);

	React.useEffect(() => {
		if (ServiceId) {
			fetch(`${process.env.PRODUCTION_URL}api/services/cards/${ServiceId}`, {
				method: "POST",
				headers: {
					"Content-Type": "text/plain",
				},
			})
				.then((res) => res.json())
				.then((data) => {
					setServiceData(data.service_data);
					setLoading(false);
				});
		}
	}, [ServiceId]);

	if (loading) {
		return <ServiceLoadingCard />;
	}

	return (
		<React.Fragment>
			<ServiceCard
				profile_context={profile_context}
				session={session}
				dispatch={dispatch}
				loading={loading}
				_id={serviceData._id}
				slug={serviceData.slug}
				title={serviceData.title}
				images={serviceData.images}
				location={serviceData.location ? serviceData.location.title : ""}
				price={serviceData.price}
				serviceType={serviceData.service_type}
				serviceSubtype={serviceData.service_subtype}
				service_provider_name={serviceData.service_provider?.name}
				listed_by={serviceData.listedBy}
				saved_content={serviceData.saved_content}
				boosting={serviceData.boost_information}
				created_by={serviceData.created_by}
				show_contact_details={serviceData.show_contact_details}
				source={source ? source : ""}
			/>
		</React.Fragment>
	);
};

export default ServiceCardFetcher;
