/*

? First, let's import the required components.

*/
import React from "react";
import { styled } from "@mui/system";
// import Searchbar from "../search/Searchbar";
import { useTheme, Typography, useMediaQuery } from "@mui/material";
import dynamic from "next/dynamic";

/*

? Next let's import all the UI sub-components we intend to use on this page.

*/
import StateInterface from "../../../redux-magic/state-interface";
import Search from "./SearchComponent/Search";
// import OnboardingModal from "../../modals/OnboardingModal/OnboardingModal";
const OnboardingModal = dynamic(() =>
	import("../../modals/OnboardingModal/OnboardingModal").then((mod) => mod.default),
);
import SearchMobile from "./SearchMobileComponent/SearchMobile";
// import Searchbar from "../search/Searchbar";

/*

? Next, let's import the select change event signature.

*/

/*

& Let's define a custom attribute for our container called index. This will help the layout container organise our component correctly.

*/

type IndexAttribute = {
	index?: number;
};

/*

& Now let's style all the components we intend to use on this page.

*/

const Container = styled("div")<IndexAttribute>(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	width: "100%",
	// backgroundSize: "cover",
	// backgroundRepeat: "no-repeat",
	// backgroundPosition: "bottom",
	// backgroundImage:
	// 	theme.palette.mode === "dark"
	// 		? "url(images/illustrations/index/NewDarkModeV2.svg)"
	// 		: "url(images/illustrations/index/NewLightModeV2.svg)",
	// height: "50vh",
	/* XXS breakpoint */
	[theme.breakpoints.only("xxs")]: {
		// backgroundImage:
		// 	theme.palette.mode === "dark"
		// 		? "url(images/illustrations/index/NewDarkMode.svg)"
		// 		: "url(images/illustrations/index/NewLightMode.svg)",
		padding: "0rem 0.5rem 0rem 0.5rem",
		// backgroundSize: "cover",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "bottom",
		// height: "35vh",
	},
	/* XS breakpoint */
	[theme.breakpoints.down("xs")]: {
		// backgroundImage:
		// 	theme.palette.mode === "dark"
		// 		? "url(images/illustrations/index/NewDarkMode.svg)"
		// 		: "url(images/illustrations/index/NewLightMode.svg)",
		padding: "3.25rem 1rem 0rem 1rem",
		// backgroundSize: "cover",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "bottom",
		// height: "35vh",
	},
	[theme.breakpoints.only("xs")]: {
		// backgroundImage:
		// 	theme.palette.mode === "dark"
		// 		? "url(images/illustrations/index/NewDarkMode.svg)"
		// 		: "url(images/illustrations/index/NewLightMode.svg)",
		padding: "3.25rem 1rem 0rem 1rem",
		// backgroundSize: "cover",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "bottom",
		// height: "35vh",
	},
	/* galaxy z fold 5 */
	[theme.breakpoints.between(343, 345)]: {
		// backgroundImage:
		// theme.palette.mode === "dark"
		// 	? "url(images/illustrations/index/NewDarkMode.svg)"
		// 	: "url(images/illustrations/index/NewLightMode.svg)",
		padding: "4rem 1rem 0rem 1rem",
		// backgroundSize: "cover",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "bottom",
		// height: "32vh",
	},
	/* iphone xr */
	[theme.breakpoints.between(412, 415)]: {
		// backgroundImage:
		// 	theme.palette.mode === "dark"
		// 		? "url(images/illustrations/index/NewDarkMode.svg)"
		// 		: "url(images/illustrations/index/NewLightMode.svg)",
		padding: "4.25rem 1rem 0rem 1rem",
		// backgroundSize: "cover",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "bottom",
		// height: "32vh",
	},
	/* iphone 14pro max */
	[theme.breakpoints.between(429, 431)]: {
		// backgroundImage:
		// 	theme.palette.mode === "dark"
		// 		? "url(images/illustrations/index/NewDarkMode.svg)"
		// 		: "url(images/illustrations/index/NewLightMode.svg)",
		padding: "4.25rem 1rem 0rem 1rem",
		// backgroundSize: "cover",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "bottom",
		// height: "33vh",
	},
	/* XSPLUS breakpoint */
	[theme.breakpoints.down("xsPlus")]: {
		// backgroundImage:
		// 	theme.palette.mode === "dark"
		// 		? "url(images/illustrations/index/NewDarkMode.svg)"
		// 		: "url(images/illustrations/index/NewLightMode.svg)",
		padding: "4rem 1.5rem 0rem 1.5rem",
		// backgroundSize: "cover",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "bottom",
		// height: "35vh",
	},
	[theme.breakpoints.only("xsPlus")]: {
		// backgroundImage:
		// 	theme.palette.mode === "dark"
		// 		? "url(images/illustrations/index/NewDarkMode.svg)"
		// 		: "url(images/illustrations/index/NewLightMode.svg)",
		padding: "4rem 1.5rem 0rem 1.5rem",
		// backgroundSize: "cover",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "bottom",
		// height: "35vh",
	},
	/* SM breakpoint */
	[theme.breakpoints.only("sm")]: {
		// backgroundImage:
		// 	theme.palette.mode === "dark"
		// 		? "url(images/illustrations/index/NewLightMode.svg)"
		// 		: "url(images/illustrations/index/NewLightModeV2.svg)",
		padding: "0rem 3rem 0rem 3rem",
		// backgroundSize: "cover",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "bottom",
		// height: "35vh",
	},
	/*ipad Mini */
	[theme.breakpoints.only("smPlus")]: {
		// backgroundImage:
		// 	theme.palette.mode === "dark"
		// 		? "url(images/illustrations/index/NewDarkModeV2.svg)"
		// 		: "url(images/illustrations/index/NewLightModeV2.svg)",
		padding: "0rem 3rem 0rem 3rem",
		// backgroundSize: "cover",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "bottom",
		// height: "35vh",
	},
	/* ipad Air*/
	[theme.breakpoints.between(819, 821)]: {
		// backgroundImage:
		// 	theme.palette.mode === "dark"
		// 		? "url(images/illustrations/index/NewDarkModeV2.svg)"
		// 		: "url(images/illustrations/index/NewLightModeV2.svg)",
		padding: "0rem 3rem 0rem 3rem",
		// backgroundSize: "cover",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "bottom",
		// height: "30vh",
	},
	[theme.breakpoints.only("sm822")]: {
		// backgroundImage:
		// theme.palette.mode === "dark"
		// 	? "url(images/illustrations/index/NewDarkModeV2.svg)"
		// 	: "url(images/illustrations/index/NewLightModeV2.svg)",
		padding: "0rem 3rem 0rem 3rem",
		// backgroundSize: "cover",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "bottom",
		// height: "30vh",
	},
	/* zenfold */
	[theme.breakpoints.between(852, 854)]: {
		// backgroundImage:
		// 	theme.palette.mode === "dark"
		// 		? "url(images/illustrations/index/NewDarkModeV2.svg)"
		// 		: "url(images/illustrations/index/NewLightModeV2.svg)",
		padding: "0rem 3rem 0rem 3rem",
		// backgroundSize: "cover",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "bottom",
		// height: "32vh",
	},
	[theme.breakpoints.only("sm860")]: {
		// backgroundImage:
		// 	theme.palette.mode === "dark"
		// 		? "url(images/illustrations/index/NewDarkModeV2.svg)"
		// 		: "url(images/illustrations/index/NewLightModeV2.svg)",
		padding: "0rem 3rem 0rem 3rem",
		// backgroundSize: "cover",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "bottom",
		// height: "32vh",
	},
	/* Surface Pro 7 */
	[theme.breakpoints.between(911, 913)]: {
		// backgroundImage:
		// 	theme.palette.mode === "dark"
		// 		? "url(images/illustrations/index/NewDarkModeV2.svg)"
		// 		: "url(images/illustrations/index/NewLightModeV2.svg)",
		padding: "0rem 3rem 0rem 3rem",
		// backgroundSize: "cover",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "bottom",
		// height: "30vh",
	},
	[theme.breakpoints.only("sm910")]: {
		// backgroundImage:
		// 	theme.palette.mode === "dark"
		// 		? "url(images/illustrations/index/NewDarkModeV2.svg)"
		// 		: "url(images/illustrations/index/NewLightModeV2.svg)",
		padding: "0rem 3rem 0rem 3rem",
		// backgroundSize: "cover",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "bottom",
		// height: "30vh",
	},

	/* MD breakpoint */
	[theme.breakpoints.only("md")]: {
		// backgroundImage:
		// 	theme.palette.mode === "dark"
		// 		? "url(images/illustrations/index/NewDarkModeV2.svg)"
		// 		: "url(images/illustrations/index/NewLightModeV2.svg)",
		padding: "0rem 4rem 0rem 4rem",
		// backgroundSize: "cover",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "bottom",
		// height: "50vh",
	},
	/* iPad Pro */
	[theme.breakpoints.between(1023, 1025)]: {
		// backgroundImage:
		// 	theme.palette.mode === "dark"
		// 		? "url(images/illustrations/index/NewDarkModeV2.svg)"
		// 		: "url(images/illustrations/index/NewLightModeV2.svg)",
		padding: "0rem 4rem 0rem 4rem",
		// backgroundSize: "cover",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "bottom",
		// height: "30vh",
	},
	/* iPad Air */
	[theme.breakpoints.between(1179, 1181)]: {
		// backgroundImage:
		// 	theme.palette.mode === "dark"
		// 		? "url(images/illustrations/index/NewDarkModeV2.svg)"
		// 		: "url(images/illustrations/index/NewLightModeV2.svg)",
		padding: "0rem 4rem 0rem 4rem",
		// backgroundSize: "cover",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "bottom",
		// height: "45vh",
	},
	// [theme.breakpoints.down("md1190")]: {
	// 	backgroundImage:
	// 		theme.palette.mode === "dark"
	// 			? "url(images/illustrations/index/NewDarkModeV2.svg)"
	// 			: "url(images/illustrations/index/NewLightModeV2.svg)",
	// 	padding: "0rem 4rem 0rem 4rem",
	// 	backgroundSize: "cover",
	// 	backgroundRepeat: "no-repeat",
	// 	backgroundPosition: "bottom",
	// 	height: "50vh",
	// },
	// 1920x1080 at 150% zoom
	[theme.breakpoints.between(1280, 1281)]: {
		// backgroundImage:
		// 	theme.palette.mode === "dark"
		// 		? "url(images/illustrations/index/NewDarkModeV2.svg)"
		// 		: "url(images/illustrations/index/NewLightModeV2.svg)",
		padding: "0rem 4rem 0rem 4rem",
		// backgroundSize: "cover",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "bottom",
		// zIndex: 0,
		// height: "50vh",
	},
	// /* LG breakpoint */
	[theme.breakpoints.only("lg")]: {
		// backgroundImage:
		// 	theme.palette.mode === "dark"
		// 		? "url(images/illustrations/index/NewDarkModeV2.svg)"
		// 		: "url(images/illustrations/index/NewLightModeV2.svg)",
		padding: "0rem 4rem 0rem 4rem",
		// backgroundSize: "cover",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "bottom",
		// zIndex: 0,
		// height: "55vh",
	},
	/* LGPLUS breakpoint */
	[theme.breakpoints.only("lgPlus")]: {
		// backgroundImage:
		// 	theme.palette.mode === "dark"
		// 		? "url(images/illustrations/index/NewDarkModeV2.svg)"
		// 		: "url(images/illustrations/index/NewLightModeV2.svg)",
		padding: "0rem 5rem 0rem 5rem",
		// backgroundSize: "cover",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "bottom",
		// zIndex: 0,
		// height: "50vh",
	},
	/* XL breakpoint */
	[theme.breakpoints.only("xl")]: {
		// backgroundImage:
		// 	theme.palette.mode === "dark"
		// 		? "url(images/illustrations/index/NewDarkModeV2.svg)"
		// 		: "url(images/illustrations/index/NewLightModeV2.svg)",
		padding: "0rem 23rem 0rem 23rem",
		// backgroundSize: "cover",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "bottom",
		// zIndex: 0,
		// height: "40vh",
	},
	/* XXL breakpoint */
	[theme.breakpoints.only("xxl")]: {
		// backgroundImage:
		// 	theme.palette.mode === "dark"
		// 		? "url(images/illustrations/index/NewDarkModeV2.svg)"
		// 		: "url(images/illustrations/index/NewLightModeV2.svg)",
		padding: "0rem 36rem 0rem 36rem",
		// backgroundSize: "cover",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "bottom",
		// zIndex: 0,
		// height: "33vh",
	},
	/* XXXL breakpoint */
	[theme.breakpoints.only("xxxl")]: {
		// backgroundImage:
		// 	theme.palette.mode === "dark"
		// 		? "url(images/illustrations/index/NewDarkModeV2.svg)"
		// 		: "url(images/illustrations/index/NewLightModeV2.svg)",
		padding: "0rem 72rem 0rem 72rem",
		// backgroundSize: "cover",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "bottom",
		// zIndex: 0,
		// height: "25vh",
	},
}));

const SnackbarContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	width: "100%",
	/* XXS breakpoint */
	[theme.breakpoints.only("xxs")]: {
		padding: "0rem 0.5rem 0rem 0.5rem",
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "bottom",
	},
	/* XS breakpoint */
	[theme.breakpoints.only("xs")]: {
		padding: "0rem 1rem 0rem 1rem",
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "bottom",
	},
	/* XSPLUS breakpoint */
	[theme.breakpoints.only("xsPlus")]: {
		padding: "0rem 1.5rem 0rem 1.5rem",
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "bottom",
	},
	/* SM breakpoint */
	[theme.breakpoints.only("sm")]: {
		padding: "0rem 3rem 0rem 3rem",
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "bottom",
	},
	/* MD breakpoint */
	[theme.breakpoints.only("md")]: {
		padding: "0rem 4rem 0rem 4rem",
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "bottom",
	},
	/* LG breakpoint */
	[theme.breakpoints.only("lg")]: {
		position: "absolute",
		zIndex: 1,
		padding: "0rem 4rem 0rem 4rem",
	},
	/* 1080p 125% breakpoint*/
	[theme.breakpoints.up(theme.breakpoints.values.lg + 150)]: {
		position: "absolute",
		zIndex: 1,
		padding: "0rem 4rem 0rem 4rem",
	},
	/* LGPLUS breakpoint */
	[theme.breakpoints.up("lgPlus")]: {
		position: "absolute",
		zIndex: 1,
		padding: "0rem 5rem 0rem 5rem",
	},
	/* XL breakpoint */
	[theme.breakpoints.up("xl")]: {
		position: "absolute",
		zIndex: 1,
		padding: "0rem 3rem 0rem 3rem",
	},

	/* XXL breakpoint */
	[theme.breakpoints.up("xxl")]: {
		position: "absolute",
		zIndex: 1,
		padding: "0rem 36rem 0rem 36rem",
	},
	/* XXXL breakpoint */
	[theme.breakpoints.up("xxxl")]: {
		position: "absolute",
		zIndex: 1,
		padding: "0rem 72rem 0rem 72rem",
	},
}));

/* 

	* The Magic Component

	*/

const MagicComponent = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	opacity: 1,
	zIndex: 15,
}));

/*

& Next, let's create the component.

*/

const HomeHeader = ({
	index,
	lookingFor,
	is_searching,
	dispatch,
	session,
	loading,
	// theme,
	searchFiltersAccordionsOrder,
	homeLocation,
	onBoardingDetails,
	onBoardingTitle,
	new_business_profile_url,
	search_filter_state,
	searchanimationsettings,
	search_filters_metrices,
}: {
	index: number;
	session: any;
	lookingFor: StateInterface["looking_for"];
	is_searching: StateInterface["is_searching"];
	dispatch: Function;
	loading: boolean;
	// theme: Theme;
	searchFiltersAccordionsOrder: Array<string>;
	homeLocation: StateInterface["home_location"];
	onBoardingDetails: StateInterface["on_boarding_details"];
	onBoardingTitle: StateInterface["on_boarding_title"];
	new_business_profile_url: StateInterface["new_business_profile_url"];
	search_filter_state: StateInterface["search_filters_state"];
	searchanimationsettings: StateInterface["search_animation_settings"];
	search_filters_metrices: StateInterface["search_filter_metrices"];
}) => {
	const theme = useTheme();
	const [animationComplete, setAnimationComplete] = React.useState(false);

	React.useEffect(() => {
		if (searchanimationsettings.StartAnimate) {
			const timer = setTimeout(() => {
				setAnimationComplete(true);
			}, 250);
			return () => clearTimeout(timer);
		} else {
			setAnimationComplete(false);
		}
	}, [searchanimationsettings.StartAnimate]);

	return (
		<React.Fragment>
			{!animationComplete ? (
				<Container
					index={index}
					sx={{
						"@keyframes CustomHeight": {
							"0%": {
								opacity: 1,
							},
							"25%": {
								opacity: 0.75,
							},
							"50%": {
								opacity: 0.5,
							},
							"75%": {
								opacity: 0.25,
							},
							"100%": {
								opacity: 0,
							},
						},
						animation: searchanimationsettings.StartAnimate ? "CustomHeight 0.25s linear 1" : "none",
						height: animationComplete ? "15vh" : "40vh",
						opacity: searchanimationsettings.StartAnimate ? 0 : 1,
						display: animationComplete ? "none" : "flex",
						paddingTop: animationComplete ? "4.5rem" : "0rem",
						[theme.breakpoints.down("sm")]: { height: "30vh" },
						/*1920 125% (1920/1.25 = 1536) */
						[theme.breakpoints.between(theme.breakpoints.values.lgPlus + 168, theme.breakpoints.values.lgPlus + 171)]: {
							height: "50vh",
						},
					}}
				>
					<MagicComponent
						sx={{
							/* iPad Mini */
							[theme.breakpoints.only("smPlus")]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "80px",
								width: "87.5%",
							},
							/* Asus ZenBook Fold */
							[theme.breakpoints.between(852, 854)]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "110px",
								width: "90%",
							},
							/* Surface Pro 7 */
							[theme.breakpoints.between(911, 913)]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "110px",
								width: "89.5%",
							},
							/* Md Devices */
							// [theme.breakpoints.down("md")]: {
							// 	display: "flex",
							// 	position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
							// 	top: "120px",
							// 	width: "88%",
							// },
							/* Md Devices */
							[theme.breakpoints.only("md")]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "120px",
								width: "88%",
							},
							/*  iPad Air */
							[theme.breakpoints.between(1179, 1181)]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "120px",
								width: "89%",
							},
							/* iPad Pro */
							[theme.breakpoints.between(1023, 1025)]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "110px",
								width: "88%",
							},
							[theme.breakpoints.between(theme.breakpoints.values.md1190 - 11, theme.breakpoints.values.sm - 12)]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "90px",
								width: "90%",
							},
							[theme.breakpoints.only("lg")]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "80px",
								width: "90%",
							},
							[theme.breakpoints.between(theme.breakpoints.values.lgPlus + 2, theme.breakpoints.values.lgPlus + 3)]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "90px",
								width: "90%",
							},
							[theme.breakpoints.only("lgPlus")]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "80px",
								width: "90%",
							},
							[theme.breakpoints.between(theme.breakpoints.values.lgPlus + 168, theme.breakpoints.values.lgPlus + 171)]:
								{
									display: "flex",
									position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
									top: "60px",
									width: "90%",
								},
							[theme.breakpoints.only("xl")]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "80px",
								width: "61.69%",
							},
							[theme.breakpoints.only("xxl")]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "100px",
								width: "55%",
							},
							[theme.breakpoints.only("xxxl")]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "125px",
								width: "40%",
							},
						}}
					>
						<Typography
							sx={{
								display: "flex",
								marginBottom: "4rem",
								fontSize: "1.75rem",
								fontWeight: "400",
								color: theme.palette.mode === "dark" ? "#ffffff" : "#000000",
								/* galaxy z fold 5 */
								[theme.breakpoints.between(343, 345)]: {
									display: "flex",
									// marginTop: "2.5rem",
									marginBottom: "1.5rem",
									fontSize: "1.25rem",
									fontWeight: "400",
								},
								/* samsung s8+ */
								[theme.breakpoints.between(359, 361)]: {
									display: "flex",
									// marginTop: "3.25rem",
									marginBottom: "1.25rem",
									fontSize: "1.25rem",
									fontWeight: "400",
								},
								/* iphone se */
								[theme.breakpoints.between(374, 376)]: {
									display: "flex",
									// marginTop: "3.25rem",
									marginBottom: "1.25rem",
									fontSize: "1.25rem",
									fontWeight: "400",
								},
								/* iphone 12 pro*/
								[theme.breakpoints.between(389, 391)]: {
									display: "flex",
									// marginTop: "3.5rem",
									marginBottom: "2rem",
									fontSize: "1.25rem",
									fontWeight: "400",
								},
								/* iphone xr */
								[theme.breakpoints.between(412, 415)]: {
									display: "flex",
									// marginTop: "3.25rem",
									marginBottom: "1.25rem",
									fontSize: "1.25rem",
									fontWeight: "400",
								},
								/* iphone 14pro max */
								[theme.breakpoints.between(429, 431)]: {
									display: "flex",
									// marginTop: "3.5rem",
									marginBottom: "2.5rem",
									fontSize: "1.25rem",
									fontWeight: "400",
								},
								[theme.breakpoints.down("xsPlus")]: {
									display: "flex",
									// marginTop: "1.5rem",
									marginBottom: "0.5rem",
									fontSize: "1.25rem",
									fontWeight: "400",
								},
								[theme.breakpoints.only("smPlus")]: {
									display: "flex",
									marginTop: "2.5rem",
									marginBottom: "1.5rem",
									fontSize: "1.75rem",
									fontWeight: "400",
								},
								/* zenfold */
								[theme.breakpoints.between(852, 854)]: {
									display: "flex",
									marginTop: "3.5rem",
									marginBottom: "2rem",
									fontSize: "1.75rem",
									fontWeight: "400",
								},
								[theme.breakpoints.between(911, 913)]: {
									display: "flex",
									marginTop: "3.5rem",
									marginBottom: "2rem",
									fontSize: "1.75rem",
									fontWeight: "400",
								},
								/* iPad Pro */
								[theme.breakpoints.between(1023, 1025)]: {
									display: "flex",
									marginTop: "3.5rem",
									marginBottom: "2rem",
									fontSize: "1.75rem",
									fontWeight: "400",
								},
								[theme.breakpoints.only("lg")]: {
									display: "flex",
									marginTop: "3.5rem",
									marginBottom: "2rem",
									fontSize: "1.75rem",
									fontWeight: "400",
								},
								[theme.breakpoints.between(theme.breakpoints.values.lg + 148, theme.breakpoints.values.lg + 152)]: {
									marginTop: "0rem",
								},
								[theme.breakpoints.only("lgPlus")]: {
									display: "flex",
									marginTop: "3.5rem",
									marginBottom: "2.5rem",
									fontSize: "1.75rem",
									fontWeight: "400",
								},
								[theme.breakpoints.only("xl")]: {
									display: "flex",
									marginTop: "3rem",
									marginBottom: "3rem",
									fontSize: "1.75rem",
									fontWeight: "400",
								},
							}}
						>
							Turbocharge your property hunt with Beegru
							{/* <br />
							Simplified with Beegru */}
						</Typography>
						<Search
							search_filter_state={search_filter_state}
							dispatch={dispatch}
							searchanimationsettings={searchanimationsettings}
						/>
					</MagicComponent>
					<SearchMobile
						search_filter_state={search_filter_state}
						dispatch={dispatch}
						searchanimationsettings={searchanimationsettings}
						search_filters_metrices={search_filters_metrices}
					/>
					{session && session.user && session.user.dbUser && session.user.dbUser.onboarding_complete === false ? (
						<OnboardingModal
							session={session}
							dispatch={dispatch}
							onBoardingDetails={onBoardingDetails}
							onBoardingTitle={onBoardingTitle}
							displayProgress={false}
							new_business_profile_url={new_business_profile_url}
						/>
					) : null}
				</Container>
			) : null}
			{/* <Container index={index}>
				<Searchbar
					index={0}
					session={session}
					theme={theme}
					dispatch={dispatch}
					lookingFor={lookingFor}
					isSearching={is_searching}
					updateReduxState={true}
					updateQueryString={false}
					enableSearchButtonHits={true}
					alwaysShowFilterButton={false}
					alwaysHideFilterButton={true}
					numberSystem={"international"}
					filtersDialogStateFromSearchPage={false}
					onChangeFiltersDialogState={false}
					searchFiltersAccordionsOrder={searchFiltersAccordionsOrder}
					includeAllResultsOption={false}
					homeLocation={homeLocation}
					homePage
				/>
				
			</Container> */}
		</React.Fragment>
	);
};

export default HomeHeader;
