/* Common imports */
import React from "react";
/* mui imports */

import {
	useTheme,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Divider,
	Typography,
	Slide,
	Button,
} from "@mui/material";
import { styled } from "@mui/system";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import BalconyIcon from "@mui/icons-material/Balcony";

/* redux imports */
import { SearchFilterId, SearchFilterOptionRange } from "../../redux-magic/sub-interfaces/search-types";
import { updateSearchFilterSelectedOptionsThunk, updateSearchFilterActiveOptionsThunk } from "../../redux-magic/store";
import StateInterface from "../../redux-magic/state-interface";
import { updateSearchFilterAppliedThunk } from "../../redux-magic/thunks";
/* stylings */

const CustomAccordion = styled(Accordion)(({ theme }) => ({
	borderRadius: "16px",
	boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
	"&:: before": {
		display: "none",
	},
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
	padding: "var(--Spacing-spacing-sm, 8px) var(--Spacing-spacing-md, 16px)",
	margin: "0rem",
}));

const CustomArrowIcon = styled(KeyboardArrowDownOutlinedIcon)(({ theme }) => ({
	width: "1.5rem",
	height: "1.5rem",
	borderRadius: "4px",
	color: theme.palette.mode == "dark" ? "#FFFFFF" : "#623816",
	backgroundColor: "#FC801929",
}));

const CustomBalconyIcon = styled(BalconyIcon)(({ theme }) => ({
	marginRight: "1rem",
	color: theme.palette.primary.main,
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	flexWrap: "wrap",
	padding: "1rem",
	margin: "0rem",
	gap: "var(--Spacing-spacing-md, 16px)",
}));

const CheckBoxDiv = styled(Button)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	borderRadius: "0.5rem",
	padding: "var(--Spacing-spacing-xs, 4px)",
	// boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
	gap: "var(--Spacing-spacing-xs, 4px)",
	cursor: "pointer",
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "0.813rem",
	fontWeight: "400",
	lineHeight: "1.125rem",
	padding: "3px 6px",
	textTransform: "capitalize",
	color: theme.palette.mode == "dark" ? "#FFFFFF" : "#623816",
}));

const DoneIcon = styled(DoneOutlinedIcon)(({ theme }) => ({
	color: "#623816",
	height: "1rem",
	width: "1rem",
}));

const AddIcon = styled(AddOutlinedIcon)(({ theme }) => ({
	color: theme.palette.mode == "dark" ? "#FFFFFF" : "#623816",
	height: "1rem",
	width: "1rem",
}));

export const _k_balconies = ({
	search_id,
	dispatch,
	balconies,
}: {
	search_id: SearchFilterId;
	balconies: StateInterface["search_filters_state"]["balconies"];
	dispatch: Function;
}) => {
	// console.log("balconies", balconies);

	// lets create a state to store the selected data
	const minBalconies: number = balconies
		? (balconies.selected_options[0] as SearchFilterOptionRange)
			? (balconies.selected_options[0] as SearchFilterOptionRange).value
				? (balconies.selected_options[0] as SearchFilterOptionRange).value.unlock_max === true
					? 5
					: (balconies.selected_options[0] as SearchFilterOptionRange).value.min
				: 0
			: 0
		: 0;

	// console.log("minBalconies", minBalconies);

	const maxBalconies: number = balconies
		? (balconies.selected_options[0] as SearchFilterOptionRange)
			? (balconies.selected_options[0] as SearchFilterOptionRange).value
				? (balconies.selected_options[0] as SearchFilterOptionRange).value.unlock_max === true
					? 5
					: (balconies.selected_options[0] as SearchFilterOptionRange).value.max
				: 0
			: 0
		: 0;

	// console.log("maxBalconies", maxBalconies);

	const [state, setState] = React.useState<Array<number>>(
		minBalconies === 0 && maxBalconies === 0 ? [] : [minBalconies, maxBalconies],
	);

	// console.log("sr=tate", state);

	const [slide, setSlide] = React.useState<boolean>(false);
	const bedroom = [
		{
			id: "0",
			title: "Studio",
			value: 0.5,
		},
		{
			id: "1",
			title: "1 ",
			value: 1,
		},
		{
			id: "2",
			title: "2 ",
			value: 2,
		},
		{
			id: "3",
			title: "3 ",
			value: 3,
		},
		{
			id: "4",
			title: "4 ",
			value: 4,
		},
		{
			id: "5",
			title: "4 +",
			value: 5,
		},
	];

	// lets call the useEffect hook to update the state

	React.useEffect(() => {
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions:
					state.length === 0
						? []
						: [
								{
									id: "balconies",
									title: "Balconies",
									value: {
										min:
											state.length === 0
												? 0
												: state.sort((a, b) => a - b)[0] === 5
													? 4
													: state.sort((a, b) => a - b)[0],
										max:
											state.length === 0
												? 0
												: state.sort((a, b) => a - b)[state.sort((a, b) => a - b).length - 1] === 5
													? 4
													: state.sort((a, b) => a - b)[state.sort((a, b) => a - b).length - 1],
										unlock_max: state.includes(5) ? true : false,
									},
								},
							],
			}),
		);
		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions: [
					{
						id: "balconies",
						title: "Balconies",
						value: {
							min: state.length === 0 ? 0 : state.sort((a, b) => a - b)[0] === 5 ? 4 : state.sort((a, b) => a - b)[0],
							max:
								state.length === 0
									? 0
									: state.sort((a, b) => a - b)[state.sort((a, b) => a - b).length - 1] === 5
										? 4
										: state.sort((a, b) => a - b)[state.sort((a, b) => a - b).length - 1],
							unlock_max: state.includes(5) ? true : false,
						},
					},
				],
			}),
		);
	}, [state, dispatch, search_id]);

	React.useEffect(() => {
		const value = balconies.selected_options[0]?.value;
		if (typeof value === "object" && "min" in value) {
			// Now TypeScript knows that value is of type SearchFilterOptionValueRange
			const isApplied = value.min !== 0 || value.max !== 0;
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: search_id,
					isApplied: isApplied,
				}),
			);
		}
	}, [balconies, search_id, dispatch]);

	/* lets create a function to remove the data from the array */

	const handleRemoveData = (value: number) => {
		//lets remove the value from the array

		// lets find the index of the value

		const index = state.indexOf(value);

		// lets remove the value

		if (index > -1) {
			const newState = [...state];
			newState.splice(index, 1);
			setState(newState);
		}
	};

	return (
		<CustomAccordion
			square={true}
			disableGutters={true}
			onChange={() => {
				setSlide(!slide);
			}}
		>
			<CustomAccordionSummary
				expandIcon={<CustomArrowIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				<CustomBalconyIcon /> Balconies{" "}
				{state.length !== 0 && (
					<Brightness1Icon sx={{ color: "primary.main", width: "8px", marginTop: "-0.25rem", marginLeft: "0.25rem" }} />
				)}
			</CustomAccordionSummary>
			<Divider
				sx={{
					borderBottom: "1px solid var(--Other-New-Divider, rgba(255, 255, 255, 0.12))",
				}}
			/>
			<Slide
				direction="up"
				in={slide}
				mountOnEnter
				unmountOnExit
			>
				<CustomAccordionDetails>
					{bedroom.map((item) => {
						const inRange = item.value >= minBalconies && item.value <= maxBalconies;
						return (
							<CheckBoxDiv
								key={item.id}
								onClick={() => {
									inRange ? handleRemoveData(item.value) : setState([...state, item.value].sort((a, b) => a - b));
								}}
								sx={{
									background: inRange ? "#FC801933" : "",
									border: inRange
										? "1px solid var(--Other-New-Outline-Border, rgba(255, 255, 255, 0.23))"
										: "1px solid #C0C0C0",
								}}
							>
								{inRange ? <DoneIcon /> : <AddIcon />}
								<Text variant="body1">{item.title}</Text>
							</CheckBoxDiv>
						);
					})}
				</CustomAccordionDetails>
			</Slide>
		</CustomAccordion>
	);
};
