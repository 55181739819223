/* Common imports */

import { styled } from "@mui/system";
import React from "react";
/* mui imports */
import { Chip, useMediaQuery, useTheme } from "@mui/material";
import StateInterface from "../../../../redux-magic/state-interface";
import { SearchFilterId, SearchFilterOption } from "../../../../redux-magic/sub-interfaces/search-types";
import {
	updateSearchFilterActiveOptionsThunk,
	updateSearchFilterAppliedThunk,
	updateSearchFilterSelectedOptionsThunk,
	updateSearchFilterVisibilityThunk,
} from "../../../../redux-magic/thunks";
import { search_type } from "../../../../lib/data/search-filters";

/* redux imports */

/* stylings */
const ServiceChips = styled(Chip)(({ theme }) => ({
	display: "flex",
	alignSelf: "stretch",
	borderRadius: "8px",
	gap: "0.5rem",
})) as typeof Chip;

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	overflowX: "scroll",
	alignItems: "center",
	gap: "1rem",
	paddingTop: "0.5rem",
	"&::-webkit-scrollbar": {
		display: "none",
	},
}));
const ServiceSubTypeComponent = ({
	search_id,
	search_filter_state,
	dispatch,
	search_type,
}: {
	search_id: SearchFilterId;
	search_filter_state: StateInterface["search_filters_state"]["service_type"];
	dispatch: Function;
	search_type: string;
}) => {
	const theme = useTheme();
	const handleClickServiceRadioType = (value: Array<SearchFilterOption>) => {
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: value,
			}),
		);
		/*
		
		* change is_selected to true for the selected option 
		
		*/
		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions: search_filter_state.active_options.map((item) => ({
					...item,
					is_selected: item.id === value[0]?.id ? true : false,
				})),
			}),
		);
	};
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	React.useEffect(() => {
		if (isMobile) {
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: search_id,
					isApplied: search_filter_state.selected_options.length != 0 && search_type === "services" ? true : false,
				}),
			);
			dispatch(
				updateSearchFilterVisibilityThunk({
					searchFilterId: search_id,
					isVisible: search_filter_state.selected_options.length != 0 && search_type === "services" ? true : false,
				}),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search_filter_state, search_id, dispatch, search_type]);
	return (
		<Container>
			{search_filter_state.active_options.slice(0, 10).map((item: SearchFilterOption) => (
				<ServiceChips
					key={item?.id}
					onClick={() => {
						if (search_filter_state?.selected_options[0]?.id !== item.id) {
							handleClickServiceRadioType([item] as Array<SearchFilterOption>);
						}
					}}
					label={item?.title}
					clickable
					color="default"
					variant="outlined"
					sx={{
						fontSize: "1rem",
						backgroundColor: search_filter_state?.selected_options[0]?.id === item.id ? "#FC801929" : "transparent",
						color:
							theme.palette.mode === "dark"
								? search_filter_state?.selected_options[0]?.id === item.id
									? "#ffffff"
									: "rgba(255, 255, 255, 1)"
								: search_filter_state?.selected_options[0]?.id === item.id
									? "rgba(98, 56, 22, 1)"
									: "black",
						border:
							theme.palette.mode === "dark"
								? search_filter_state?.selected_options[0]?.id === item.id
									? "1px solid transparent"
									: "1px solid rgba(255, 255, 255, 0.23)"
								: search_filter_state?.selected_options[0]?.id === item.id
									? "1px solid transparent"
									: "1px solid rgba(0, 0, 0, 0.12)",
						"&.MuiChip-clickable:hover": {
							backgroundColor: search_filter_state?.selected_options[0]?.id === item.id ? "#FC801929" : "transparent",
						},
					}}
				/>
			))}
		</Container>
	);
};
export default ServiceSubTypeComponent;
