/*

? First, let's import the essentials - React, Layout, styled and Theme.

*/

import { styled } from "@mui/system";
import React from "react";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import { Typography, useTheme } from "@mui/material";
import CapitalizeFirstLetter from "../../../../lib/CapitalizeFirstLetter";

/*

& Next, let's style all the components we intend to use on this page.

*/

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-end",
	alignItems: "flex-start",
	width: "100%",
	height: "100%",
	margin: "0rem 0.2rem 0rem 0rem",
}));

const ServiceListCardSubType = ({ service_subtype }: { service_subtype: Array<string> }) => {
	const theme = useTheme();

	return (
		<React.Fragment>
			<Container>
				<Typography variant="body1">
					{service_subtype[0].length > 20
						? CapitalizeFirstLetter(service_subtype[0].split("_").join(" ").substring(0, 20)) + "..."
						: CapitalizeFirstLetter(service_subtype[0].split("_").join(" "))}
				</Typography>

				<Typography
					variant="body2"
					color={theme.palette.text.secondary}
				>
					Type
				</Typography>
			</Container>
		</React.Fragment>
	);
};

export default ServiceListCardSubType;
