/* Common imports */
import React from "react";
/* mui imports */
import { styled } from "@mui/system";
import { Chip, useTheme, useMediaQuery } from "@mui/material";
import {
	SearchFilterId,
	SearchFilterOption,
	SearchFilterOptionTextActive,
} from "../../../../redux-magic/sub-interfaces/search-types";
import {
	updateSearchFilterActiveOptionsThunk,
	updateSearchFilterAppliedThunk,
	updateSearchFilterSelectedOptionsThunk,
	updateSearchFilterVisibilityThunk,
	setSearchAnimationDetailsThunk,
} from "../../../../redux-magic/thunks";
import StateInterface from "../../../../redux-magic/state-interface";

/* stylings */

const PropertyChips = styled(Chip)(({ theme }) => ({
	display: "flex",
	alignSelf: "stretch",
	borderRadius: "8px",
	gap: "0.5rem",
})) as typeof Chip;

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	overflowX: "scroll",
	alignItems: "center",
	gap: "1rem",
	paddingTop: "0.5rem",
	"&::-webkit-scrollbar": {
		display: "none",
	},
}));

const PropertyTypeMobile = ({
	search_id,
	property_category_value,
	search_filter_state,
	dispatch,
	searchanimationsettings,
	TabValue,
}: {
	search_id: SearchFilterId;
	property_category_value: string;
	search_filter_state: StateInterface["search_filters_state"]["property_type"];
	dispatch: Function;
	searchanimationsettings: StateInterface["search_animation_settings"];
	TabValue: string;
}) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const handleClickRadioPropertyType = (value: Array<SearchFilterOption>) => {
		if (
			value[0].id === "plot" ||
			value[0].id === "sez" ||
			value[0].id === "building" ||
			value[0].id === "shop" ||
			value[0].id === "office_space" ||
			value[0].id === "retail" ||
			value[0].id === "hospitality" ||
			value[0].id === "healthcare" ||
			value[0].id === "educational" ||
			value[0].id === "farm_plot" ||
			value[0].id === "co_working" ||
			value[0].id === "factory" ||
			value[0].id === "plot_commercial"
		) {
			dispatch(
				setSearchAnimationDetailsThunk({
					StartAnimate: searchanimationsettings.StartAnimate,
					TriggerSearch: searchanimationsettings.TriggerSearch,
					PropertySubCategory: ["Budget", "Status", "Listed By", "Facing direction"],
					ResetSearch: searchanimationsettings.ResetSearch,
					HomeAccordion: searchanimationsettings.HomeAccordion,
				}),
			);
		} else if (
			value[0].id === "warehouse_industrial" ||
			value[0].id === "warehouse_cold_storage" ||
			value[0].id === "warehouse_commercial" ||
			value[0].id === "warehouse"
		) {
			dispatch(
				setSearchAnimationDetailsThunk({
					StartAnimate: searchanimationsettings.StartAnimate,
					TriggerSearch: searchanimationsettings.TriggerSearch,
					PropertySubCategory: ["Budget", "Status", "Listed By"],
					ResetSearch: searchanimationsettings.ResetSearch,
					HomeAccordion: searchanimationsettings.HomeAccordion,
				}),
			);
		} else if (
			value[0].id === "land_commercial" ||
			value[0].id === "land_residential" ||
			value[0].id === "land_agricultural" ||
			value[0].id === "land_industrial"
		) {
			dispatch(
				setSearchAnimationDetailsThunk({
					StartAnimate: searchanimationsettings.StartAnimate,
					TriggerSearch: searchanimationsettings.TriggerSearch,
					PropertySubCategory: ["Budget", "Listed By"],
					ResetSearch: searchanimationsettings.ResetSearch,
					HomeAccordion: searchanimationsettings.HomeAccordion,
				}),
			);
		} else if (value[0].id === "mining") {
			dispatch(
				setSearchAnimationDetailsThunk({
					StartAnimate: searchanimationsettings.StartAnimate,
					TriggerSearch: searchanimationsettings.TriggerSearch,
					PropertySubCategory: ["Budget", "Listed By", "Facing direction"],
					ResetSearch: searchanimationsettings.ResetSearch,
					HomeAccordion: searchanimationsettings.HomeAccordion,
				}),
			);
		} else {
			dispatch(
				setSearchAnimationDetailsThunk({
					StartAnimate: searchanimationsettings.StartAnimate,
					TriggerSearch: searchanimationsettings.TriggerSearch,
					PropertySubCategory: ["Budget", "Bedroom", "Status", "Listed By", "Facing direction"],
					ResetSearch: searchanimationsettings.ResetSearch,
					HomeAccordion: searchanimationsettings.HomeAccordion,
				}),
			);
		}

		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: value,
			}),
		);

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions: search_filter_state.active_options.map((item) => ({
					...item,
					is_selected: item.id === value[0]?.id ? true : false,
				})),
			}),
		);
	};

	React.useEffect(() => {
		if (isMobile) {
			if (TabValue === "Buy" || TabValue === "Rent" || TabValue === "Open for business") {
				dispatch(
					updateSearchFilterAppliedThunk({
						searchFilterId: search_id,
						isApplied: search_filter_state.selected_options.length != 0 ? true : false,
					}),
				);
				search_filter_state.affects.map((item: SearchFilterId) => {
					dispatch(
						updateSearchFilterVisibilityThunk({
							searchFilterId: item,
							isVisible: search_filter_state.selected_options.length != 0 ? true : false,
						}),
					);

					if (search_filter_state.selected_options.length === 0) {
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: item,
								newOptions: [],
							}),
						);
					}
				});
			} else {
				null;
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search_filter_state, search_id, dispatch, TabValue]);

	/* 

* setting the initial values of selected item and scrolling to the selected item

*/
	let selectedItem = search_filter_state?.selected_options[0];
	const containerRef = React.useRef<HTMLDivElement>(null);
	/* 

* trigger only when the selected item changes and in mobile

*/
	React.useEffect(() => {
		if (isMobile) {
			if (selectedItem && containerRef.current) {
				containerRef.current.scroll({ left: 0, behavior: "smooth" });
			}
		}
	}, [selectedItem, isMobile]);
	return (
		<Container ref={containerRef}>
			{search_filter_state.active_options
				? [...search_filter_state.active_options]
						.sort((a, b) => {
							if (search_filter_state?.selected_options[0]?.id === a.id) return -1;
							if (search_filter_state?.selected_options[0]?.id === b.id) return 1;
							return 0;
						})
						.map((item: SearchFilterOptionTextActive) => (
							<PropertyChips
								key={item?.id}
								onClick={() => {
									if (search_filter_state?.selected_options[0]?.id !== item.id) {
										handleClickRadioPropertyType([item] as Array<SearchFilterOptionTextActive>);
									}
								}}
								label={item.title === "Co-living" ? "Co-living/PG" : item.title}
								clickable
								color="default"
								variant="outlined"
								sx={{
									fontSize: "1rem",
									backgroundColor:
										search_filter_state?.selected_options[0]?.id === item.id ? "#FC801929" : "transparent",
									color:
										theme.palette.mode === "dark"
											? search_filter_state?.selected_options[0]?.id === item.id
												? "#ffffff"
												: "rgba(255, 255, 255, 1)"
											: search_filter_state?.selected_options[0]?.id === item.id
												? "rgba(98, 56, 22, 1)"
												: "black",
									border:
										theme.palette.mode === "dark"
											? search_filter_state?.selected_options[0]?.id === item.id
												? "1px solid transparent"
												: "1px solid rgba(255, 255, 255, 0.23)"
											: search_filter_state?.selected_options[0]?.id === item.id
												? "1px solid transparent"
												: "1px solid rgba(0, 0, 0, 0.12)",
									"&.MuiChip-clickable:hover": {
										backgroundColor:
											search_filter_state?.selected_options[0]?.id === item.id ? "#FC801929" : "transparent",
									},
								}}
							/>
						))
				: []}
		</Container>
	);
};
export default PropertyTypeMobile;
