/*

? First, let's import the required components.

*/
import { display, styled } from "@mui/system";
import React from "react";

import { Autocomplete, InputAdornment, TextField, Typography, useTheme, useMediaQuery } from "@mui/material";
import MyLocationIcon from "@mui/icons-material/MyLocation";
/*

* Google Maps Imports

*/
import { useLoadScript } from "@react-google-maps/api";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import StateInterface from "../../../../redux-magic/state-interface";
import { updateSearchFilterSelectedOptionsThunk } from "../../../../redux-magic/store";
import { SearchFilterId } from "../../../../redux-magic/sub-interfaces/search-types";
import { updateSearchFilterAppliedThunk } from "../../../../redux-magic/thunks";

/*

* Interface for location

*/

interface Suggestion {
	description: string;
	matched_substrings: Array<{ length: number; offset: number }>;
	place_id: string;
	reference: string;
	structured_formatting: {
		main_text: string;
		main_text_matched_substrings: Array<{ length: number; offset: number }>;
		secondary_text: string;
	};
	terms: Array<{ offset: number; value: string }>;
	types: Array<string>;
}

interface GeolocationCoordinates {
	latitude: number;
	longitude: number;
	altitude: number | null;
	accuracy: number;
	altitudeAccuracy: number | null;
	heading: number | null;
	speed: number | null;
}
interface GeolocationPosition {
	coords: GeolocationCoordinates;
}
interface GMRGCPlusCode {
	compound_code: string;
	global_code: string;
}
interface GMRGCResultsAddressComponent {
	long_name: string;
	short_name: string;
	types: Array<string>;
}
interface GMRGCResultsGeometryLocation {
	lat: number;
	lng: number;
}
interface GMRGCResultsGeometryBoundsAndGeometryViewport {
	northeast: GMRGCResultsGeometryLocation;
	southwest: GMRGCResultsGeometryLocation;
}
interface GMRGCResultsGeometry {
	bounds: GMRGCResultsGeometryBoundsAndGeometryViewport;
	location: GMRGCResultsGeometryLocation;
	location_type: string;
}
interface GMRGCResults {
	address_components: Array<GMRGCResultsAddressComponent>;
	formatted_address: string;
	geometry: GMRGCResultsGeometry;
	viewport: GMRGCResultsGeometryBoundsAndGeometryViewport;
	place_id: string;
	types: Array<string>;
}
interface GoogleMapsReverseGeocodingApiResponse {
	plus_code: GMRGCPlusCode;
	results: Array<GMRGCResults>;
	status: string;
}

/*

& Next, let's create the component.

*/

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "flex-start",
	justifyContent: "flex-start",
	width: "100%",
	padding: "0.5rem 1rem 0.5rem 1rem",
	height: "10.438rem",
	backgroundColor: theme.palette.background.paper,
	boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.12)",
	borderRadius: "8px",
	position: "relative",
}));

const RotatingItem = styled("div")(({ theme }) => ({
	"@keyframes rotate": {
		"0%,10%": {
			transform: "translateY(0px)",
			animationDelay: "4s",
			"#ri": {
				"&:after": {
					// prettier-ignore
					content: '"..."',
				},
			},
		},
		"20%,30%": {
			transform: "translateY(-22px)",
			animationDelay: "4s",
		},
		"40%,50%": {
			transform: "translateY(-46px)",
			animationDelay: "4s",
		},
		"60%,70%": {
			transform: "translateY(-69px)",
			animationDelay: "4s",
		},
		"80%,90%": {
			transform: "translateY(-92px)",
			animationDelay: "4s",
		},
		"100%": {
			transform: "translateY(0px)",
			animationDelay: "4s",
		},
	},
	fontSize: "1rem",
	color: theme.palette.mode === "dark" ? "#ffffff" : "rgba(102, 102, 102, 1)",
	animation: `rotate 6s linear infinite`,
}));

const RotatingText = styled("div")(({ theme }) => ({
	flexDirection: "column",
	height: "1.3rem",
	overflow: "hidden",
	color: theme.palette.mode === "dark" ? theme.palette.primary.main : "rgba(102, 102, 102, 1)",
	gap: "0.25rem",
}));

const OptionContainer = styled("li")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "flex-start",
	width: "100%",
	padding: "0.25rem 0.5rem",
}));

const OptionText = styled(Typography, {
	shouldForwardProp: (prop) => prop !== "isPrimary",
})<any>(({ theme, isPrimary }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	fontSize: "0.875rem",
	lineHeight: "1.3125rem",
	fontWeight: 400,
	color: isPrimary ? theme.palette.primary.main : theme.palette.text.primary,
})) as any;

const OptionSubtext = styled(Typography)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	fontSize: "0.875rem",
	lineHeight: "1.3125rem",
	fontWeight: 400,
	color: theme.palette.text.secondary,
})) as typeof Typography;

const TextfieldSearch = styled(TextField)(({ theme }) => ({
	color: theme.palette.mode === "dark" ? "rgba(255, 255, 255,0.7)" : "rgba(102, 102, 102, 1)",
})) as typeof TextField;

const librariesToLoad: ("places" | "drawing" | "geometry" | "localContext" | "visualization")[] = [
	"places",
	"drawing",
	"geometry",
];

const LocationAutoComplete = ({
	search_id,
	dispatch,
	search_filter_state,
}: {
	search_id: SearchFilterId;
	dispatch: Function;
	search_filter_state: StateInterface["search_filters_state"]["location"];
}) => {
	const theme = useTheme();
	/*

  & let's declare the states for the usePlacesAutocomplete hook

  */
	const {
		value,
		suggestions: { data },
		setValue,
		init,
	} = usePlacesAutocomplete({
		initOnMount: false,
		debounce: 500,
	});

	/*

  & let's declare the states for the useLoadScript hook

  */
	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY_NO_REFERRER_RESTRICTIONS || "",
		libraries: librariesToLoad as any,
	});
	const [options, setOptions] = React.useState<string[]>([]);

	const getCurrentLocation = () => {
		/*
    & Next, let's check if the user has allowed us to use their location and update the corresponding state accordingly.
    */
		if (!navigator.geolocation) {
			return;
		} else {
			/*
      & Next, since it looks like the user has allowed us to use their location, let's get their current location. Let's begin by disabling the location autocomplete input.
      */

			navigator.geolocation.getCurrentPosition(
				async (position: GeolocationPosition) => {
					/*
          & Next, let's extract the latitude and longitude from the position object.
          */
					const { latitude, longitude }: { latitude: number; longitude: number } = position.coords;
					/*
          & Next, let's use Google Maps' Reverse Geocoding API to get the user's current address.
          */
					const googleMapsReverseGeocodingApiResponse: GoogleMapsReverseGeocodingApiResponse = await (
						await fetch(
							"https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
								latitude +
								"," +
								longitude +
								"&key=" +
								process.env.GOOGLE_MAPS_API_KEY_NO_REFERRER_RESTRICTIONS,
							{ cache: "no-store" },
						)
					).json();

					/*
          & Next, let's extract the user's current address from the API response object.
          */
					const userCurrentAddress: string | undefined =
						googleMapsReverseGeocodingApiResponse?.results[0]?.address_components?.find(
							(address_component: GMRGCResultsAddressComponent) =>
								address_component.types.includes("sublocality_level_1"),
						)?.long_name;
					const userCurrentAreas: Array<string> | undefined = googleMapsReverseGeocodingApiResponse?.results.map(
						(result) => {
							return result.address_components?.find(
								(address_component: GMRGCResultsAddressComponent) =>
									address_component.types.includes("sublocality_level_1") ||
									address_component.types.includes("postal_town") ||
									address_component.types.includes("locality"),
							)?.long_name as string;
						},
					);

					let userCurrentArea: string | undefined;

					let resultPos: number = 0;

					for (let i = 0; i < userCurrentAreas.length; i++) {
						if (userCurrentAreas[i] !== "Bengaluru") {
							userCurrentArea = userCurrentAreas[i];
							resultPos = i;
							break;
						}
					}

					/*
          & Next, let's update the corresponding state with the user's current address. Let's also set the userLocationAllowed state to true and the allowUserInputInLocationAutocomplete state to false.
          */
					const label = userCurrentAddress ? userCurrentAddress : userCurrentArea ? userCurrentArea : "";
					setValue(label);
					dispatch(
						updateSearchFilterSelectedOptionsThunk({
							searchFilterId: search_id,
							newOptions: [
								{
									id: "location",
									title: "Location",
									value: {
										title: label,
										coordinates: [longitude, latitude],
									},
								},
							],
						}),
					);
					dispatch(
						updateSearchFilterAppliedThunk({
							searchFilterId: search_id,
							isApplied: true,
						}),
					);
				},
				async (error) => {},
				{ timeout: 10000, enableHighAccuracy: true },
			);
		}
	};

	/*

  & let's define the function to handle the change in the location autocomplete

  */
	const onChangeLocationAutocomplete = async (event: React.SyntheticEvent<HTMLSelectElement>, tvalue: any) => {
		setValue("");
		if (tvalue === "Use current location") {
			getCurrentLocation();
		} else {
			const item = data.find((res) => res.place_id === tvalue);
			if (item) {
				const temp = item.structured_formatting.main_text;

				if (Number.isNaN(+temp)) {
					// setFromList(temp);
					setValue(temp);
				} else {
				}
				// setFromList(null);
			}
			const { lat, lng } = getLatLng((await getGeocode({ placeId: tvalue }))[0]);

			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: search_id,
					newOptions: [
						{
							id: "location",
							title: "Location",
							value: {
								title: (item && item.structured_formatting && item.structured_formatting.main_text) || "",
								coordinates: [lng, lat],
							},
						},
					],
				}),
			);
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: search_id,
					isApplied: true,
				}),
			);
		}
	};

	/*

  & let's use the useEffect hook to set the value of the location autocomplete to the default location and initialize the autocomplete

  */

	React.useEffect(() => {
		if (isLoaded) {
			init();
		}
	}, [isLoaded, init]);

	React.useEffect(() => {
		init();
	}, [init, setValue]);

	React.useEffect(() => {
		const mapped = ["Use current location", ...data.map((suggestion: Suggestion) => suggestion?.place_id)].filter(
			(option: string, position: number, self: Array<string>) =>
				option !== "" && self.findIndex((item: string) => item === option) === position,
		);
		setOptions(mapped);
	}, [data]);

	/* 
	
	* hide text
	
	*/

	const [isAdornmentVisible, setAdornmentVisible] = React.useState(false);

	const handleAdornmentClick = (e: any) => {
		if (e === "") {
			setAdornmentVisible(true);
		}
	};
	const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
	React.useEffect(() => {
		if (isDesktop) {
			const selectedOption = search_filter_state?.selected_options[0]?.value;
			if (typeof selectedOption === "object" && "title" in selectedOption) {
				setValue(selectedOption.title);
				setAdornmentVisible(true);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search_filter_state]);
	const places = [
		"WhiteField",
		"Cubbon Park",
		"3rd Sector HSR Layout",
		"Koramangala",
		"Indiranagar",
		"Electronic City",
		"Marathahalli",
		"JP Nagar",
		"BTM Layout",
		"Jayanagar",
		"Bannerghatta Road",
		"Girinagar",
		"Malleshwaram",
	];
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "flex-start",
				alignItems: "center",
				width: "100%",
				height: "100%",
			}}
		>
			<Autocomplete
				disablePortal
				freeSolo={true}
				id="combo-box-demo"
				filterOptions={() => options}
				options={options}
				isOptionEqualToValue={(option: string, value: string) => {
					const item = data.find((res: any) => res.structured_formatting.main_text === value)?.place_id;
					return !item ? false : option === item;
				}}
				value={value}
				renderOption={(props, option, state, ownerState) => {
					const las: any = data.find((suggestion: any) => suggestion.place_id === option);

					const optionText: string | undefined =
						option === "Use current location" ? "Use current location" : las?.structured_formatting?.main_text;
					const optionSubtext: string | undefined =
						option === "Use current location"
							? "Use current location"
							: las
								? las?.structured_formatting?.secondary_text
								: undefined;
					/* 
						
						* if value is equal to optionText then return null

						*/

					if (value === optionText) {
						return null;
					}

					return (
						<OptionContainer
							{...props}
							sx={{
								display: value === optionText ? "none" : "flex",
							}}
						>
							<OptionText
								variant="body1"
								component="span"
								isPrimary={option === "Use current location"}
							>
								{optionText}
							</OptionText>
							<OptionSubtext
								variant="body2"
								component="span"
							>
								{optionSubtext ? optionSubtext : "in the middle of nowhere"}
							</OptionSubtext>
						</OptionContainer>
					);
				}}
				onChange={onChangeLocationAutocomplete}
				// disabled={updateLocationButtonDisabled}
				sx={{ width: "100%" }}
				renderInput={(params) => (
					<TextField
						sx={{
							display: "flex",
							justifyContent: "center",
							height: "100%",
							width: "100%",
							position: "relative",
							"& input": {
								position: "relative",
								zIndex: 1,
								color: "transparent",
								caretColor: "transparent",
							},
							"&:before": {
								content:
									search_filter_state.is_applied || isAdornmentVisible ? `"${value}"` : '"Search \\"WhiteField\\""',
								position: "absolute",
								top: "50%",
								left: "10px",
								transform: "translateY(-50%)",
								whiteSpace: "nowrap",
								overflow: "hidden",
								fontSize: "1rem",
								color: theme.palette.mode === "dark" ? "#ffffff" : "#666666",
								animation:
									search_filter_state.is_applied || isAdornmentVisible
										? "none"
										: "placeholderAnimation 19s linear infinite",
							},
							"@keyframes placeholderAnimation": {
								/* very stupid format to implement */
								"0%, 8.33%": {
									content: `"Search \\"${places[0]}\\""`,
								},
								"8.34%, 16.66%": {
									content: `"Search \\"${places[1]}\\""`,
								},
								"16.67%, 25%": {
									content: `"Search \\"${places[2]}\\""`,
								},
								"25.01%, 33.33%": {
									content: `"Search \\"${places[3]}\\""`,
								},
								"33.34%, 41.66%": {
									content: `"Search \\"${places[4]}\\""`,
								},
								"41.67%, 50%": {
									content: `"Search \\"${places[5]}\\""`,
								},
								"50.01%, 58.33%": {
									content: `"Search \\"${places[6]}\\""`,
								},
								"58.34%, 66.66%": {
									content: `"Search \\"${places[7]}\\""`,
								},
								"66.67%, 75%": {
									content: `"Search \\"${places[8]}\\""`,
								},
								"75.01%, 83.33%": {
									content: `"Search \\"${places[9]}\\""`,
								},
								"83.34%, 91.66%": {
									content: `"Search \\"${places[10]}\\""`,
								},
								"91.67%, 100%": {
									content: `"Search \\"${places[11]}\\""`,
								},
							},
							"& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
								width: 0,
								minWidth: "0px",
							},
						}}
						variant="standard"
						{...params}
						onClick={(e) => {
							if (value === "") {
								setAdornmentVisible(true);
							}
						}}
						onChange={(e) => {
							setValue(e.target.value);
							handleAdornmentClick(e.target.value);
						}}
						InputProps={{
							...params.InputProps,
							disableUnderline: true,

							endAdornment: (
								<InputAdornment position="end">
									<MyLocationIcon
										sx={{
											cursor: "pointer",
											color: "#FC8019",
										}}
										onClick={() => {
											getCurrentLocation();
										}}
									/>
								</InputAdornment>
							),
						}}
					/>
				)}
			/>
		</div>
	);
};

export default LocationAutoComplete;
