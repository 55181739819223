/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Redux Imports */

import StateInterface from "../../../../redux-magic/state-interface";
import { Price, PricePerUnit, Area, BHK } from "../../../../redux-magic/sub-interfaces/sub-interfaces";
import { ObjectId } from "mongodb";

/* Component Imports */

import Link from "@mui/material/Link";
import PropertyCardImage from "../../GlobalPropertyCardNew/PropertyCardGridView/sub-components/PropertyCardImage";
import PropertyCardTitle from "../../GlobalPropertyCardNew/PropertyCardGridView/sub-components/PropertyCardTitle";
import PropertyCardConfigAndContact from "../../GlobalPropertyCardNew/PropertyCardGridView/sub-components/PropertyCardConfigAndContact";
import PropertyCardPrice from "../../GlobalPropertyCardNew/PropertyCardGridView/sub-components/PropertyCardPrice";
import PropertyCardLocation from "../../GlobalPropertyCardNew/PropertyCardGridView/sub-components/PropertyCardLocation";
import PropertyCardType from "../../GlobalPropertyCardNew/PropertyCardGridView/sub-components/PropertyCardType";
import PropertyCardArea from "./sub-components/PropertyCardArea";
import PropertyCardStatus from "../../GlobalPropertyCardNew/PropertyCardGridView/sub-components/PropertyCardStatus";
import { Chip, useTheme } from "@mui/material";

/* Library Function Imports */

import PrepareConfigString from "../../../../lib/PrepareConfigString";
import prepareConfigTitle from "../../../../lib/PrepareConfigTitle";

/* Styled Components */

const PropertyLink = styled(Link)(({ theme }) => ({
	textDecoration: "none",
	color: theme.palette.mode == "dark" ? "#fff" : "#000",
	"&:hover": {
		textDecoration: "none",
	},
}));

const PropertyCardContainer = styled("div", {
	shouldForwardProp: (prop) => prop !== "source",
})<{ source: string }>(({ theme, source }) => ({
	background: theme.palette.background.paper,
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "16px",
	objectFit: "contain",
	width: source === "search" ? "100%" : "22.05rem",
	height: "29.65rem",
	margin: "0.1rem",
	boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
	scrollSnapAlign: "start",
	/* XXS breakpoint */
	[theme.breakpoints.up("xxs")]: {
		width: source === "search" ? "16rem" : "18rem",
		height: "100%",
	},
	/* iphone 5 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 39)]: {
		width: source === "search" ? "17.75rem" : "18.05rem",
		height: "100%",
	},
	/*galaxy S8+ - 360*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
		width: source === "search" ? "20.25rem" : "20.5rem",
		height: "100%",
	},
	/*iphone se - 375*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 94)]: {
		width: source === "search" ? "21.25rem" : "21.25rem",
		height: "100%",
	},
	/* iphone 12 pro and pixel - 390 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 108.95)]: {
		width: source === "search" ? "22rem" : "22.05rem",
		height: "100%",
	},
	/*iphone XR - 414*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
		width: source === "search" ? "23.5rem" : "22.05rem",
		height: "29.65rem",
	},
	/*iphone 14 pro max - 430*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 149)]: {
		width: source === "search" ? "24.5rem" : "22.05rem",
		height: "29.65rem",
	},
	[theme.breakpoints.up("xsPlus")]: {
		width: source === "search" ? "30.5rem" : "22.05rem",
		height: "29.65rem",
	},
	[theme.breakpoints.up("sm")]: {
		width: "22.05rem",
		height: "29.65rem",
	},
	/*ipad Mini */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		width: "22.05rem",
		height: "29.65rem",
	},
	/* ipad Air*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
		width: "22.05rem",
		height: "29.65rem",
	},
	[theme.breakpoints.up("md")]: {
		width: "22.05rem",
		height: "29.65rem",
	},
	/* MDLG Breakpoint iPadPro*/
	[theme.breakpoints.up(theme.breakpoints.values.md + 64)]: {
		width: "22.05rem",
		height: "29.65rem",
	},
	/*720p and 768p breakpoint */
	[theme.breakpoints.up("lg")]: {
		width: "22.05rem",
		height: "29.65rem",
	},
	/* 1080p 125% breakpoint*/
	[theme.breakpoints.up(theme.breakpoints.values.lg + 150)]: {
		width: "22.05rem",
		height: "29.65rem",
	},
	/* 1080p breakpoint*/
	[theme.breakpoints.up("xl")]: {
		width: "22.05rem",
		height: "29.65rem",
	},
	/* XXL breakpoint  2560p*/
	[theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
		width: "22.05rem",
		height: "29.65rem",
	},
	/*4k breakpoint 3840p*/
	[theme.breakpoints.up(theme.breakpoints.values.xl + 1920)]: {
		width: "22.05rem",
		height: "29.65rem",
	},
}));

const PropertyCardInfoContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	padding: "0.75rem 1.25rem 0rem 1.25rem",
	margin: "0rem 0rem 0rem 0rem",
	width: "100%",
	height: "100%",
	borderRadius: "0px 0px 16px 16px",
	cursor: "pointer",
	[theme.breakpoints.down(413)]: {
		padding: "0.75rem 1.25rem 1rem 1.25rem",
	},
}));

const TitleContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "space-between",
	padding: "1rem 1.25rem 0rem 1rem",
	width: "100%",
}));

const PriceAndTransactionContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
}));

const CustomChip = styled(Chip, {
	shouldForwardProp: (prop) => prop !== "transaction_type",
})<{ transaction_type: string }>(({ theme, transaction_type }) => ({
	height: "1.75rem",
	fontSize: "0.875rem",
	letterSpacing: "0.02857em",
	borderRadius: "8px",
	backgroundColor:
		transaction_type === "buy"
			? "#c6fae9"
			: transaction_type === "rent"
				? "#ffc6ac"
				: transaction_type === "resale"
					? "#7cedff"
					: "#c6fae9",
	color:
		transaction_type === "buy"
			? "#1e4620"
			: transaction_type === "rent"
				? "#4c2708"
				: transaction_type === "resale"
					? "#212121"
					: "#1e4620",
}));

const CardRow = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	marginBottom: "1rem",
	width: "100%",
}));

const VerticalLine = styled("div")(({ theme }) => ({
	borderLeft: "1px solid #D9D9D9",
	height: "2rem",
}));

const PropertyCard = ({
	profile_context,
	session,
	dispatch,
	loading,
	_id,
	slug,
	transaction_type,
	property_type,
	title,
	images,
	property_status,
	possession_date,
	tags,
	location,
	developer,
	price,
	price_per_unit,
	area,
	bhk,
	floors,
	no_of_seats,
	saved_content,
	page_type,
	created_by,
	assigned_to,
	isEditor,
	boosting,
	preview,
	show_contact_details,
	source,
}: {
	profile_context: StateInterface["profile_context"];
	session: any;
	dispatch: Function;
	loading: boolean;
	_id: ObjectId | string;
	slug: string;
	transaction_type: string;
	property_type: Array<string>;
	title: string;
	images: string;
	property_status: Array<string>;
	possession_date: string;
	tags: Array<string>;
	location: string;
	developer: string;
	price: Price;
	price_per_unit: PricePerUnit;
	area: Area;
	bhk: BHK;
	floors: BHK;
	no_of_seats: BHK;
	saved_content: Array<any>;
	page_type?: string;
	created_by?: any;
	assigned_to?: StateInterface["single_business_profile"]["business_profile"]["assigned_to"];
	isEditor?: boolean;
	boosting: StateInterface["property"]["boost_information"];
	preview?: boolean;
	show_contact_details: boolean;
	source?: string;
}) => {
	const theme = useTheme();

	let BoostingCheck = boosting?.active ? true : false;

	return (
		<React.Fragment>
			<PropertyLink
				href={preview ? "javascript:void(0)" : `/properties/${slug}`}
				rel="noopener"
				target={preview ? "_self" : "_blank"}
				referrerPolicy="no-referrer"
				title={title ? title : "Unknown Title"}
			>
				<PropertyCardContainer source={source ? source : ""}>
					{/* Image */}

					<PropertyCardImage
						_id={_id}
						slug={slug}
						images={images}
						dispatch={dispatch}
						session={session}
						profile_context={profile_context}
						loading={loading}
						saved_content={saved_content}
						page_type={page_type}
						created_by={created_by}
						assigned_to={assigned_to}
						isEditor={isEditor}
						boosting={boosting}
						preview={preview}
					/>

					<TitleContainer
						sx={{
							// borderBottom: BoostingCheck ? "3px solid #fdb375" : "none",
							borderRight: BoostingCheck ? "3px solid #fdb375" : "none",
							borderLeft: BoostingCheck ? "3px solid #fdb375" : "none",
							background: BoostingCheck ? "#fc80190d" : theme.palette.background.paper,
						}}
					>
						<PriceAndTransactionContainer>
							{/* Price */}

							<PropertyCardPrice
								loading={loading}
								price={price}
							/>

							{/* Transaction Type Chip */}

							<CustomChip
								label={
									transaction_type && transaction_type === "buy"
										? "Sale"
										: transaction_type.charAt(0).toUpperCase() + transaction_type.slice(1)
								}
								transaction_type={transaction_type}
							/>
						</PriceAndTransactionContainer>

						{/* Title */}

						<PropertyCardTitle
							loading={loading}
							title={title !== "" || undefined ? title : "Unknown Title"}
						/>
					</TitleContainer>

					<PropertyCardInfoContainer>
						<CardRow>
							{/* Property Type */}

							<PropertyCardType
								loading={loading}
								propertyType={property_type ? property_type : []}
							/>

							<VerticalLine />

							{/* Location */}

							<PropertyCardLocation
								loading={loading}
								location={location !== "" || undefined ? location : "Unknown Location"}
							/>
						</CardRow>

						<CardRow>
							{/* Area */}

							<PropertyCardArea
								loading={loading}
								area={area}
								areaType={
									property_type
										? property_type.includes("Land (Residential)") ||
											property_type.includes("Land (Commercial)") ||
											property_type.includes("Land (Agricultural)") ||
											property_type.includes("Land (Industrial)")
											? "land_area"
											: "super_built_up_area"
										: "super_built_up_area"
								}
							/>

							<VerticalLine />

							{/* Property Status */}

							<PropertyCardStatus
								loading={loading}
								property_status={property_status}
								possession_date={possession_date}
								transaction_type={transaction_type}
								property_type={property_type}
							/>
						</CardRow>

						{/* Config and CTA */}

						<PropertyCardConfigAndContact
							loading={loading}
							developer={developer !== "" || undefined ? developer : created_by.business_profile_id.title}
							config={PrepareConfigString(transaction_type, property_type, bhk, price_per_unit, floors, no_of_seats)}
							card_title={prepareConfigTitle(transaction_type, property_type)}
							dispatch={dispatch}
							session={session}
							slug={slug}
							title={title}
							created_by={created_by}
							show_contact_details={show_contact_details}
						/>
					</PropertyCardInfoContainer>
				</PropertyCardContainer>
			</PropertyLink>
		</React.Fragment>
	);
};

export default PropertyCard;
