/*

? First, let's import the essentials - React, Layout, styled and Theme.

*/

import { styled } from "@mui/system";

/*

& Next, let's style all the components we intend to use on this page.

*/

const Image = styled("img")(({ theme }) => ({
	width: "45%",
	height: "100%",
	objectFit: "cover",
	borderRadius: "16px",
}));

const ServiceListCardImage = ({ loading, imagesrc, alt }: { loading: boolean; imagesrc: string; alt: string }) => {
	return (
		<Image
			src={imagesrc ? imagesrc[0] : "/images/uploads/placeholder.jpg"}
			alt={alt}
			referrerPolicy="no-referrer"
		/>
	);
};

export default ServiceListCardImage;
