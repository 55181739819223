/* Common Imports */

import React from "react";
import { styled, useTheme } from "@mui/system";

/* Component Imports */

import { Chip, IconButton, Paper } from "@mui/material";
import { ObjectId } from "mongoose";
import Link from "next/link";
import ServiceListCardImage from "./sub-components/ServiceListCardImage";
import ServiceListCardPrice from "./sub-components/ServiceListCardPrice";
import ServiceListCardTitle from "./sub-components/ServiceListCardTitle";
import ServiceListCardLocation from "./sub-components/ServiceListCardLocation";
import ServiceListCardSubType from "./sub-components/ServiceListCardSubType";
import ServiceListCardProviderAndContact from "./sub-components/ServiceListCardProviderAndContact";
import ServiceListLoadingCard from "../skeletonLoadingCard/ServiceListLoadingCard";
import { BookmarkBorder } from "@mui/icons-material";

/*

& Next, let's style all the components we intend to use on this page.

*/

const ServiceLink = styled(Link)(({ theme }) => ({
	textDecoration: "none",
	height: "auto",
	color: theme.palette.mode == "dark" ? "#fff" : "#000",
	"&:hover": {
		textDecoration: "none",
	},
}));

const MainContainer = styled(Paper)(({ theme }) => ({
	background: theme.palette.background.paper,
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "16px",
	objectFit: "contain",
	width: "100%",
	height: "15rem",
	boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
	padding: "1.25rem",
	gap: "1.5rem",
	[theme.breakpoints.down("sm")]: { display: "none" },
	/*ipad Mini */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		width: "42rem",
	},
	/* ipad Air*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
		width: "45rem",
	},
	[theme.breakpoints.up("sm822")]: {
		width: "47rem",
	},
	[theme.breakpoints.up("sm910")]: {
		width: "51rem",
	},
	[theme.breakpoints.up("md")]: {
		width: "55.5rem",
	},
	/*720p and 768p breakpoint */
	[theme.breakpoints.up("lg")]: {
		width: "54rem",
	},
	/* 1080p 125% breakpoint*/
	[theme.breakpoints.up(theme.breakpoints.values.lg + 150)]: {
		width: "64rem",
	},
	/* 1080p breakpoint*/
	[theme.breakpoints.up("xl")]: {
		width: "55rem",
	},
	/* XXL breakpoint  2560p*/
	[theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
		width: "65.5rem",
	},
	/*4k breakpoint 3840p*/
	[theme.breakpoints.up(theme.breakpoints.values.xl + 1920)]: {
		width: "71rem",
	},
}));

const ServiceCardInfoContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	height: "100%",
	width: "60%",
	overflow: "hidden",
}));

const PriceAndTransactionContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
}));

const IconsContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	gap: "0.5rem",
}));

const CustomChip = styled(Chip)(({ theme }) => ({
	height: "2.25rem",
	fontSize: "0.875rem",
	letterSpacing: "0.02857em",
	padding: "0rem 0.5rem",
	borderRadius: "16px",
	backgroundColor: "#fff6bd",
	color: "#212121",
}));

const CardRow = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	marginBottom: "1.25rem",
	width: "100%",
}));

const VerticalLine = styled("div")(({ theme }) => ({
	borderLeft: "1px solid #D9D9D9",
	height: "2rem",
}));

const ServiceCardListView = ({
	ServiceId,
	dispatch,
	session,
}: {
	ServiceId?: ObjectId | string;
	dispatch: Function;
	session: any;
}) => {
	const theme = useTheme();

	const [serviceData, setServiceData] = React.useState<any>({});

	const [loading, setLoading] = React.useState(true);

	React.useEffect(() => {
		fetch(`${process.env.PRODUCTION_API_URL}services/cards/${ServiceId}`, {
			method: "POST",
			headers: {
				"Content-Type": "text/plain",
			},
		})
			.then((res) => res.json())
			.then((data) => {
				setServiceData(data.service_data);
				setLoading(false);
			});
	}, [ServiceId]);

	let BoostingCheck = serviceData?.boost_information?.active ? true : false;

	if (loading) {
		return <ServiceListLoadingCard />;
	}

	return (
		<ServiceLink
			href={`/services/${serviceData.slug}`}
			rel="noopener"
			target="_blank"
			referrerPolicy="no-referrer"
			title={serviceData.title || "Unknown Title"}
		>
			<MainContainer
				sx={{
					border: BoostingCheck ? "3px solid #fdb375" : "none",
					background: BoostingCheck ? "#fc80190d" : theme.palette.background.paper,
				}}
			>
				{/* Image */}

				<ServiceListCardImage
					loading={loading}
					imagesrc={serviceData.images}
					alt="Service"
				/>

				<ServiceCardInfoContainer>
					<PriceAndTransactionContainer>
						{/* Price */}

						<ServiceListCardPrice
							showPrice={serviceData.price?.show_price}
							price={serviceData.price}
						/>

						<IconsContainer>
							{/* Transaction Type Chip */}

							<CustomChip label="Service" />

							{/* Bookmark Icon */}

							<IconButton
								aria-label="bookmark"
								style={{
									background: theme.palette.mode === "dark" ? "#FC801933" : "#FC801929",
									borderRadius: "12px",
								}}
							>
								<BookmarkBorder
									fontSize="small"
									style={{ color: theme.palette.mode === "dark" ? "#FFFFFF" : "#623816" }}
								/>
							</IconButton>
						</IconsContainer>
					</PriceAndTransactionContainer>

					{/* Title */}

					<ServiceListCardTitle title={serviceData.title !== "" ? serviceData.title : "Unknown Title"} />

					<CardRow>
						{/* Service Subtype */}

						<ServiceListCardSubType service_subtype={serviceData.service_subtype} />

						<VerticalLine />

						{/* Location */}

						<ServiceListCardLocation
							location={serviceData.location?.title !== "" ? serviceData.location?.title : "Unknown Location"}
						/>
					</CardRow>

					{/* Service Provider and CTA */}

					<ServiceListCardProviderAndContact
						service_provider_name={serviceData.service_provider?.name ? serviceData.service_provider?.name : "Unknown"}
						dispatch={dispatch}
						session={session}
						slug={serviceData.slug}
						title={serviceData.title}
						created_by={serviceData.created_by}
						show_contact_details={serviceData.show_contact_details}
					/>
				</ServiceCardInfoContainer>
			</MainContainer>
		</ServiceLink>
	);
};

export default ServiceCardListView;
