/*

? First, let's import the required components.

*/
import React from "react";
import { styled } from "@mui/system";
import LocationAutoComplete from "./LocationAutoComplete";
import {
	Tabs,
	Tab,
	Paper,
	Collapse,
	Chip,
	Stack,
	Radio,
	RadioGroup,
	FormControlLabel,
	FormControl,
	FormLabel,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	useTheme,
	// Button,
	useMediaQuery,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import BeegruButton from "../../../common-components/buttons/BeegruButton";
import StateInterface from "../../../../redux-magic/state-interface";
// import { updateSearchFilterSelectedOptionsThunk } from "../../../../redux-magic/thunks";
// import { SearchFilterId, SearchFilterOption } from "../../../../redux-magic/sub-interfaces/search-types";
import {
	setSearchAnimationDetailsThunk,
	updateSearchFilterSelectedOptionsThunk,
	updateSearchFilterAppliedThunk,
	updateSearchFilterVisibilityThunk,
} from "../../../../redux-magic/thunks";
import PropertyCategory from "./PropertyCategory";
import PropertyType from "./PropertyType";
import ServiceCategoryComponent from "./ServiceCategoryComponent";
import BudgetComponent from "./BudgetComponent";
import BedroomComponent from "./BedroomComponent";
import PropertyStatusComponent from "./PropertyStatusComponent";
import ListedByComponent from "./ListedByComponent";
import FacingDirectionComponent from "./FacingDirectionComponent";
import ServiceSubTypeComponent from "./ServiceSubTypeComponent";
/*

& Next, let's create the component.

*/

/* 

* Overiding the default styles of the Accordion Component

*/

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "flex-start",
	justifyContent: "flex-start",
	width: "100%",
	padding: "0rem 1rem 0rem 1rem",
	height: "9.7438rem",
	backgroundColor: theme.palette.mode === "dark" ? "#424242" : theme.palette.background.paper,
	[theme.breakpoints.down("sm")]: {
		display: "none",
	},
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
	width: "fit-content",
	alignSelf: "start",
	borderRadius: "8px",
	"&.MuiTabs-root > .MuiTabs-scroller > .MuiTabs-indicator": {
		display: "none",
	},
	minHeight: "0rem",
	height: "auto",
})) as typeof Tabs;

const VerticalLine = styled("div")(({ theme }) => ({
	borderLeft: theme.palette.mode === "dark" ? "rgba(255, 255, 255,0.23)" : "1px solid #D9D9D9",
	height: "2rem",
}));

const StyledTab = styled(Tab)(({ theme, label }) => ({
	textTransform: "none",
	fontSize: "0.875rem",
	fontWeight: 500,
	gap: "0.5rem",
	letterSpacing: "0.025rem",
	background: "transparent",
	"&.Mui-selected": {
		background: theme.palette.mode === "dark" ? "#16855b" : "#E8F6F1",
		color: theme.palette.mode === "dark" ? "#7fffcf" : "#1BA672",
		borderRadius: "8px",
		border: theme.palette.mode === "dark" ? "1px solid #7fffcf" : "1px solid #1BA672",
	},
})) as typeof Tab;

const HorizontalLine = styled("div")(({ theme }) => ({
	borderTop: theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.12)" : "1px solid #D9D9D9",
	width: "100%",
	color: "rgba(0, 0, 0, 0.12)",
}));

const FilterPaper = styled(Paper)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	width: "100%",
	padding: "1rem",
	backgroundColor: theme.palette.mode === "dark" ? "#424242" : theme.palette.background.paper,
	borderRadius: "0px 0px 8px 8px",
	boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.12)",
	backgroundImage: "none",
})) as typeof Paper;

const PropertyChips = styled(Chip)(({ theme }) => ({
	display: "flex",
	alignSelf: "stretch",
	borderRadius: "8px",
	gap: "0.5rem",
})) as typeof Chip;

const CommonContainerSubCategory = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-start",
	width: "100%",
	gao: "0.25rem",
	padding: "1rem 0rem 0.5rem 0rem",
}));

const HomeContainer = styled("div")(({ theme }) => ({
	"@keyframes fade": {
		"0%": {
			opacity: 0,
		},
		"100%": {
			opacity: 1,
		},
	},
}));

const CollapseContainer = styled(Collapse)(({ theme }) => ({
	width: "100%",
	backgroundColor: theme.palette.mode === "dark" ? "#424242" : theme.palette.background.paper,
	[theme.breakpoints.down("smPlus")]: {
		display: "none",
	},
}));
const Search = ({
	search_filter_state,
	dispatch,
	searchanimationsettings,
}: {
	search_filter_state: StateInterface["search_filters_state"];
	dispatch: Function;
	searchanimationsettings: StateInterface["search_animation_settings"];
}) => {
	const theme = useTheme();

	const BusinessUsers = ["Agents", "Developers", "Landowners", "Professionals"];

	/* 
	
	* Tabs state and functions buy, rent, open for business services and business users
	
	*/

	const [value, setValue] = React.useState("Buy");

	/* 

	* Function to handle the business users agent, developers, landowners, professionals, users.

	*/

	const handleChangeBusinessUsers = (value: string) => {
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_category", isApplied: false }));
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_type", isApplied: false }));

		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_category", isVisible: false }));
		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_type", isVisible: false }));
		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_status", isVisible: false }));

		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_category", isApplied: false }));
		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_category", isVisible: false }));
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_subtype", isApplied: false }));

		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_subtype", isVisible: false }));
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_type", isApplied: false }));
		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_type", isVisible: false }));

		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: "transaction_type",
				newOptions: [],
			}),
		);
		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: "transaction_type",
				isApplied: false,
			}),
		);
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: "search_type",
				newOptions: [
					{
						id: value.toLocaleLowerCase(),
						title: value,
						value: value.toLocaleLowerCase(),
					},
				],
			}),
		);
		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: "search_type",
				isApplied: true,
			}),
		);
	};

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setValue(newValue);
		/*

		* Setting the search filter selected options based on the transaction type

		*/
		if (newValue === "Buy" || newValue === "Open for business" || newValue === "Rent") {
			/* 
			 
			 * need to update transaction type as well as search type same with isApplied true. 

				! default value is done in index.tsx page for transaction_type 
			 
			*/
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "transaction_type",
					newOptions: [
						{
							id: newValue === "Open for business" ? "ofb" : newValue.toLocaleLowerCase(),
							title: newValue,
							value: newValue === "Open for business" ? "ofb" : newValue.toLocaleLowerCase(),
						},
					],
				}),
			);
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: "transaction_type",
					isApplied: true,
				}),
			);
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "search_type",
					newOptions: [
						{
							id: "properties",
							title: "Properties",
							value: "properties",
						},
					],
				}),
			);
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: "search_type",
					isApplied: true,
				}),
			);
			/* 
			
			* updating service is applied and is visible
			
			*/
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_category", isApplied: false }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_type", isVisible: false }));
			/* 
			
			* updating properties is applied and is visible
			
			*/
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_category", isVisible: true }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_type", isVisible: true }));

			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_category", isApplied: true }));
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_type", isApplied: true }));
			if (newValue === "Rent") {
				dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "security_deposit", isVisible: true }));
			}
		} else if (newValue === "Services") {
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "transaction_type",
					newOptions: [],
				}),
			);
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: "transaction_type",
					isApplied: false,
				}),
			);
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "search_type",
					newOptions: [
						{
							id: newValue.toLocaleLowerCase(),
							title: newValue,
							value: newValue.toLocaleLowerCase(),
						},
					],
				}),
			);
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: "search_type",
					isApplied: true,
				}),
			);
			// dispatch(
			// 	updateSearchFilterSelectedOptionsThunk({
			// 		searchFilterId: "property_type",
			// 		newOptions: [],
			// 	}),
			// );
			/* 
			
			* updating services is applied and is visible
			
			*/
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_category", isApplied: true }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_type", isVisible: true }));

			/* 
			
			* updating properties is applied and is visible
			
			*/
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_category", isApplied: false }));
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_type", isApplied: false }));

			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_category", isVisible: false }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_type", isVisible: false }));
		} else if (newValue === "Business users") {
			const value = "Agents";
			handleChangeBusinessUsers(value);
		}
	};
	/*

	* Filter transition effect state 

	*/
	const [checked, setChecked] = React.useState(false);

	/*
	
	* Function to handle the filter state
	
	
	*/
	const handleChangeFilters = () => {
		setChecked((prev) => !prev);
	};

	/*
	
	* SubCategory i.e (Budget, Bedroom, Status, Listed By, Facing direction)
	
	*/
	const [SubCategoryChecked, setSubCategoryChecked] = React.useState<string>("");

	const handleClickSubCategoryChips = (data: string) => {
		setSubCategoryChecked(data);
	};

	const handleDeleteSubCategoryChips = (data: string) => {
		if (SubCategoryChecked === data) {
			setSubCategoryChecked("");
		}
	};

	React.useEffect(() => {
		if (
			search_filter_state.property_category.selected_options[0]?.id ||
			search_filter_state.property_type.selected_options[0]?.id
		) {
			setSubCategoryChecked("");
		}
	}, [search_filter_state.property_category, search_filter_state.property_type]);
	/* 
	
	* Function for Search Filters for property category 
	
	*/

	/*
	
	* Check the subcategory and render the content accordingly

	*/
	const SubCategoryChipsComponent = (
		<Stack
			direction="row"
			spacing={2}
		>
			{searchanimationsettings?.PropertySubCategory?.map((data: any, id: any) => (
				<PropertyChips
					key={id}
					sx={{
						fontSize: "1rem",
						backgroundColor: SubCategoryChecked === data ? "#FC801929" : "transparent",
						color:
							theme.palette.mode === "dark"
								? SubCategoryChecked === data
									? "#ffffff"
									: "rgba(255, 255, 255, 1)"
								: SubCategoryChecked === data
									? "rgba(98, 56, 22, 1)"
									: "black",
						border:
							theme.palette.mode === "dark"
								? SubCategoryChecked === data
									? "1px solid transparent"
									: "1px solid rgba(255, 255, 255, 0.23)"
								: SubCategoryChecked === data
									? "1px solid transparent"
									: "1px solid rgba(0, 0, 0, 0.12)",

						"&.MuiChip-clickable:hover": {
							backgroundColor: SubCategoryChecked === data ? "#FC801929" : "transparent",
						},
					}}
					label={data}
					clickable
					color="default"
					variant="outlined"
					deleteIcon={SubCategoryChecked === data ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					onDelete={() => {
						SubCategoryChecked === data ? handleDeleteSubCategoryChips(data) : handleClickSubCategoryChips(data);
					}}
					onClick={() => {
						SubCategoryChecked === data ? handleDeleteSubCategoryChips(data) : handleClickSubCategoryChips(data);
					}}
				/>
			))}
		</Stack>
	);
	/* 

	* Changed from switch to css, even if conditions doesnt work. on switch or if conditions state changes to empty. so the whole css dispaly none 

	*/
	const dataToRenderSubCategoryContent = (Subvalue: string) => {
		return (
			<React.Fragment>
				<CommonContainerSubCategory
					sx={{
						gap: "0.5rem",
						display: Subvalue === "Budget" ? "flex" : "none",
					}}
				>
					<BudgetComponent
						search_id="budget"
						dispatch={dispatch}
						search_filter_state={search_filter_state.budget}
					/>
				</CommonContainerSubCategory>
				<CommonContainerSubCategory
					sx={{
						display: Subvalue === "Bedroom" ? "flex" : "none",
					}}
				>
					<BedroomComponent
						search_id="bedrooms"
						dispatch={dispatch}
						search_filter_state={search_filter_state.bedrooms}
					/>
				</CommonContainerSubCategory>
				<CommonContainerSubCategory
					sx={{
						display: Subvalue === "Status" ? "flex" : "none",
					}}
				>
					<PropertyStatusComponent
						search_id="property_status"
						dispatch={dispatch}
						search_filter_state={search_filter_state.property_status}
						propertyType={search_filter_state.property_type.selected_options[0]?.id as string}
						transactionType={search_filter_state.transaction_type.selected_options[0]?.id as string}
					/>
				</CommonContainerSubCategory>
				<CommonContainerSubCategory
					sx={{
						display: Subvalue === "Listed By" ? "flex" : "none",
					}}
				>
					<ListedByComponent
						search_id="listed_by"
						dispatch={dispatch}
						search_filter_state={search_filter_state.listed_by}
					/>
				</CommonContainerSubCategory>
				<CommonContainerSubCategory
					sx={{
						display: Subvalue === "Facing direction" ? "flex" : "none",
					}}
				>
					<FacingDirectionComponent
						search_id="land_facing"
						dispatch={dispatch}
						search_filter_state={search_filter_state.land_facing}
					/>
				</CommonContainerSubCategory>
			</React.Fragment>
		);
	};

	const SubCategoryContentComponent = <>{dataToRenderSubCategoryContent(SubCategoryChecked)}</>;

	/*
	
	* if tabs state changes then render the content accordingly
	
	*/

	const TransitionDivComponentToRender = (value: string) => {
		const valuegg = value === "Buy" || value === "Rent" || value === "Open for business" ? value : null;
		switch (value) {
			case valuegg:
				return (
					<>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								width: "100%",
							}}
						>
							<Accordion
								sx={{
									boxShadow: "none",
									backgroundColor: theme.palette.mode === "dark" ? "#424242" : theme.palette.background.paper,
									backgroundImage: "none",
									"&.Mui-expanded": {
										margin: "0rem",
									},
								}}
								defaultExpanded
							>
								<AccordionSummary
									expandIcon={
										<ExpandMoreIcon
											sx={{
												width: "1.25em",
												height: "1.25em",
												borderRadius: "8px",
												background: theme.palette.mode === "dark" ? "transparent" : "rgba(252, 128, 25, 0.16)",
												color: theme.palette.mode === "dark" ? "#FF8A29" : "rgba(98, 56, 22, 1)",
											}}
										/>
									}
									aria-controls="panel1-content"
									id="panel1-header"
									sx={{
										display: "flex",
										"&.MuiAccordionSummary-root.Mui-expanded": {
											minHeight: "0rem",
										},
										"& .MuiAccordionSummary-content.Mui-expanded": {
											margin: "0.5rem 0rem 0.5rem 0rem",
										},
									}}
								>
									Property Type
								</AccordionSummary>
								<AccordionDetails
									sx={{
										padding: "8px 14px 0px",
										"&.MuiAccordionDetails-root": {
											padding: "0rem 0.875rem 0rem 0.875rem",
										},
									}}
								>
									<PropertyCategory
										search_id={"property_category"}
										search_filter_state={search_filter_state.property_category}
										dispatch={dispatch}
										searchanimationsettings={searchanimationsettings}
									/>
									<PropertyType
										search_id="property_type"
										property_category_value={
											search_filter_state.property_category?.selected_options[0]?.value as string
										}
										dispatch={dispatch}
										search_filter_state={search_filter_state.property_type}
										searchanimationsettings={searchanimationsettings}
									/>
								</AccordionDetails>
							</Accordion>
							<Accordion
								sx={{
									boxShadow: "none",
									backgroundColor: theme.palette.mode === "dark" ? "#424242" : theme.palette.background.paper,
									backgroundImage: "none",
									"&.Mui-expanded": {
										margin: "0rem",
									},
								}}
							>
								<AccordionSummary
									expandIcon={
										<ExpandMoreIcon
											sx={{
												width: "1.25em",
												height: "1.25em",
												borderRadius: "8px",
												background: theme.palette.mode === "dark" ? "transparent" : "rgba(252, 128, 25, 0.16)",
												color: theme.palette.mode === "dark" ? "#FF8A29" : "rgba(98, 56, 22, 1)",
											}}
										/>
									}
									aria-controls="panel1-content"
									id="panel1-header"
									sx={{
										display: "flex",
										"&.MuiAccordionSummary-root.Mui-expanded": {
											minHeight: "0rem",
										},
										"& .MuiAccordionSummary-content.Mui-expanded": {
											margin: "0.5rem 0rem 0.5rem 0rem",
										},
									}}
								>
									Sub Category Type
								</AccordionSummary>
								<AccordionDetails
									sx={{
										padding: "4px 14px 0px",
									}}
								>
									{SubCategoryChipsComponent}
									{SubCategoryContentComponent}
								</AccordionDetails>
							</Accordion>
						</div>
					</>
				);
			case "Services":
				return (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							width: "100%",
						}}
					>
						<Accordion
							sx={{
								boxShadow: "none",
								backgroundColor: theme.palette.mode === "dark" ? "#424242" : theme.palette.background.paper,
								backgroundImage: "none",
								"&.Mui-expanded": {
									margin: "0rem",
								},
							}}
							defaultExpanded
						>
							<AccordionSummary
								expandIcon={
									<ExpandMoreIcon
										sx={{
											width: "1.25em",
											height: "1.25em",
											borderRadius: "8px",
											background: "rgba(252, 128, 25, 0.16)",
											color: "rgba(98, 56, 22, 1)",
										}}
									/>
								}
								aria-controls="panel1-content"
								id="panel1-header"
								sx={{
									display: "flex",
									"&.MuiAccordionSummary-root.Mui-expanded": {
										minHeight: "0rem",
									},
									"& .MuiAccordionSummary-content.Mui-expanded": {
										margin: "0.5rem 0rem 0.5rem 0rem",
									},
								}}
							>
								Service Type
							</AccordionSummary>
							<AccordionDetails
								sx={{
									padding: "0px 14px 0px",
								}}
							>
								<ServiceCategoryComponent
									search_id={"service_type"}
									search_filter_state={search_filter_state.service_type}
									dispatch={dispatch}
								/>
							</AccordionDetails>
						</Accordion>
						<Accordion
							sx={{
								boxShadow: "none",
								backgroundColor: theme.palette.mode === "dark" ? "#424242" : theme.palette.background.paper,
								backgroundImage: "none",
								"&.Mui-expanded": {
									margin: "0rem",
								},
							}}
						>
							<AccordionSummary
								expandIcon={
									<ExpandMoreIcon
										sx={{
											width: "1.25em",
											height: "1.25em",
											borderRadius: "8px",
											background: "rgba(252, 128, 25, 0.16)",
											color: "rgba(98, 56, 22, 1)",
										}}
									/>
								}
								aria-controls="panel1-content"
								id="panel1-header"
								sx={{
									display: "flex",
									"&.MuiAccordionSummary-root.Mui-expanded": {
										minHeight: "0rem",
									},
									"& .MuiAccordionSummary-content.Mui-expanded": {
										margin: "0.5rem 0rem 0.5rem 0rem",
									},
								}}
							>
								Services SubType
							</AccordionSummary>
							<AccordionDetails
								sx={{
									padding: "8px 14px 0px",
								}}
							>
								<ServiceSubTypeComponent
									search_id={"service_subtype"}
									search_filter_state={search_filter_state.service_subtype}
									dispatch={dispatch}
								/>
							</AccordionDetails>
						</Accordion>
					</div>
				);
			case "Business users":
				return (
					<div>
						<CommonContainerSubCategory
							sx={{
								paddingLeft: "1rem",
							}}
						>
							<FormControl>
								<FormLabel id="demo-row-radio-buttons-group-label"></FormLabel>
								<RadioGroup
									row
									aria-labelledby="demo-row-radio-buttons-group-label"
									name="row-radio-buttons-group"
									sx={{ gap: "0.5rem", fontSize: "1rem", fontWeight: 400 }}
								>
									{BusinessUsers.map((data, id) => (
										<FormControlLabel
											key={id}
											value={data}
											control={<Radio />}
											label={data}
											onChange={() => handleChangeBusinessUsers(data)}
										/>
									))}
								</RadioGroup>
							</FormControl>
						</CommonContainerSubCategory>
					</div>
				);
		}
	};

	const CheckerRef = React.useRef<HTMLDivElement>(null);
	/*
	
	* if user clicks outside the filter then close the filter and remove background opacity. HomeAccordion dispatch is handling that ez pz lemon squeezy
	
	*/
	const isDesktop = useMediaQuery(theme.breakpoints.up("md1220"));
	React.useEffect(() => {
		if (isDesktop) {
			const UserClickedOutsideMf = (event: MouseEvent) => {
				if (CheckerRef.current && !CheckerRef.current.contains(event.target as Node)) {
					setChecked(false);
					dispatch(
						setSearchAnimationDetailsThunk({
							StartAnimate: searchanimationsettings.StartAnimate,
							TriggerSearch: searchanimationsettings.TriggerSearch,
							ResetSearch: searchanimationsettings.ResetSearch,
							PropertySubCategory: searchanimationsettings.PropertySubCategory,
							HomeAccordion: false,
						}),
					);
				}
			};
			document.addEventListener("mousedown", UserClickedOutsideMf);
			return () => {
				document.removeEventListener("mousedown", UserClickedOutsideMf);
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDesktop]);
	return (
		<React.Fragment>
			<div ref={CheckerRef}>
				<Container
					sx={{
						borderRadius: checked ? "16px 16px 0px 0px" : "16px",
					}}
				>
					<HomeContainer
						sx={{
							height: "70%",
							margin: "0.75rem 0rem 0rem 0rem",
							width: "100%",
						}}
					>
						<StyledTabs
							sx={{
								"&.MuiTab-root": {
									bacground: "transparent",
									minHeight: "0rem",
								},
							}}
							value={value}
							onChange={handleChange}
							aria-label="wrapped label tabs example"
						>
							<StyledTab
								value="Buy"
								label="Buy"
							/>
							<StyledTab
								value="Rent"
								label="Rent"
							/>
							<StyledTab
								value="Open for business"
								label="Open for business"
							/>
							<StyledTab
								value="Services"
								label="Services"
							/>
							<StyledTab
								value="Business users"
								label="Business users"
							/>
						</StyledTabs>
					</HomeContainer>
					<HorizontalLine
						sx={{
							display: "flex",
							margin: "0.5rem 0rem 0rem 0rem",
						}}
					/>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-start",
							alignItems: "center",
							width: "100%",
							height: "100%",
						}}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								gap: "0.5rem",
								padding: "0.5rem",
								cursor: "pointer",
								width: "17%",
								[theme.breakpoints.down("md1220")]: {
									width: "22%",
								},
							}}
							onClick={() => {
								if (window.scrollY > 0) {
									window.scrollTo({ top: 0, behavior: "smooth" });
									setTimeout(() => {
										handleChangeFilters();
										dispatch(
											setSearchAnimationDetailsThunk({
												StartAnimate: searchanimationsettings.StartAnimate,
												TriggerSearch: searchanimationsettings.TriggerSearch,
												ResetSearch: searchanimationsettings.ResetSearch,
												PropertySubCategory: searchanimationsettings.PropertySubCategory,
												HomeAccordion: !checked,
											}),
										);
									}, 500);
								} else {
									handleChangeFilters();
									dispatch(
										setSearchAnimationDetailsThunk({
											StartAnimate: searchanimationsettings.StartAnimate,
											TriggerSearch: searchanimationsettings.TriggerSearch,
											ResetSearch: searchanimationsettings.ResetSearch,
											PropertySubCategory: searchanimationsettings.PropertySubCategory,
											HomeAccordion: !checked,
										}),
									);
								}
							}}
						>
							{value === "Buy" || value === "Rent" || value === "Open for business"
								? "Property"
								: value === "Services"
									? "Service"
									: "Business"}{" "}
							Type
						</div>
						<div
							style={{
								display: "flex",
								justifyContent: "flex-end",
							}}
						>
							{checked ? (
								<KeyboardArrowUpIcon
									sx={{
										cursor: "pointer",
										color: theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.56)" : "rgba(0, 0, 0, 0.54)",
									}}
									onClick={() => {
										handleChangeFilters();

										dispatch(
											setSearchAnimationDetailsThunk({
												StartAnimate: searchanimationsettings.StartAnimate,
												TriggerSearch: searchanimationsettings.TriggerSearch,
												ResetSearch: searchanimationsettings.ResetSearch,
												PropertySubCategory: searchanimationsettings.PropertySubCategory,
												HomeAccordion: !checked,
											}),
										);
									}}
								/>
							) : (
								<KeyboardArrowDownIcon
									sx={{
										cursor: "pointer",
										color: theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.56)" : "rgba(0, 0, 0, 0.54)",
									}}
									onClick={() => {
										handleChangeFilters();
										dispatch(
											setSearchAnimationDetailsThunk({
												StartAnimate: searchanimationsettings.StartAnimate,
												TriggerSearch: searchanimationsettings.TriggerSearch,
												ResetSearch: searchanimationsettings.ResetSearch,
												PropertySubCategory: searchanimationsettings.PropertySubCategory,
												HomeAccordion: !checked,
											}),
										);
									}}
								/>
							)}
						</div>
						<VerticalLine />
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								marginLeft: "0.5rem",
								width: "83%",
								alignItems: "center",
								[theme.breakpoints.down("md1220")]: {
									width: "78%",
								},
							}}
						>
							<LocationAutoComplete
								search_id="location"
								dispatch={dispatch}
								search_filter_state={search_filter_state.location}
							/>
							<BeegruButton
								disabled={!search_filter_state.location.is_applied ? true : false}
								variant="contained"
								size="large"
								// sx={{
								// 	borderRadius: "8px",
								// 	background: "#1BA672",
								// 	boxShadow: "none",
								// 	textTransform: "capitalize",
								// 	"&:hover": { background: "#1BA672", boxShadow: "none" },
								// }}
								onClick={() => {
									dispatch(
										setSearchAnimationDetailsThunk({
											StartAnimate: true,
											TriggerSearch: true,
											ResetSearch: searchanimationsettings.ResetSearch,
											PropertySubCategory: searchanimationsettings.PropertySubCategory,
											HomeAccordion: false,
										}),
									);
								}}
							>
								Search
							</BeegruButton>
						</div>
					</div>
				</Container>
				<CollapseContainer in={checked}>
					<FilterPaper elevation={4}>
						{TransitionDivComponentToRender(value)}
						<div
							style={{
								display: "flex",
								justifyContent: "flex-end",
								gap: "2.5rem",
							}}
						>
							<BeegruButton
								variant="text"
								size="medium"
								// sx={{
								// 	borderRadius: "8px",
								// 	background: "transparent",
								// 	boxShadow: "none",
								// 	textTransform: "capitalize",
								// 	color: "#1BA672",
								// 	border: "1px solid #1BA672",
								// 	"&:hover": { background: "transparent", color: "#1BA672", border: "1px solid #1BA672" },
								// }}
							>
								Clear all Filters
							</BeegruButton>
							<BeegruButton
								disabled={!search_filter_state.location.is_applied ? true : false}
								variant="outlined"
								size="medium"
								// sx={{
								// 	borderRadius: "8px",
								// 	background: "transparent",
								// 	boxShadow: "none",
								// 	textTransform: "capitalize",
								// 	color: "#1BA672",
								// 	border: "1px solid #1BA672",
								// 	"&:hover": { background: "transparent", color: "#1BA672", border: "1px solid #1BA672" },
								// }}
								onClick={() => {
									dispatch(
										setSearchAnimationDetailsThunk({
											StartAnimate: true,
											TriggerSearch: true,
											ResetSearch: searchanimationsettings.ResetSearch,
											PropertySubCategory: searchanimationsettings.PropertySubCategory,
											HomeAccordion: false,
										}),
									);
								}}
							>
								Apply Filters
							</BeegruButton>
						</div>
					</FilterPaper>
				</CollapseContainer>
			</div>
		</React.Fragment>
	);
};

export default Search;
