/* Common Imports */

import React from "react";
import PropertyCard from "./PropertyCardGridView/PropertyCardGridView";

/* Component Imports */

import PropertyLoadingCard from "../skeletonLoadingCard/PropertyLoadingCard";

/* Styled Components */

const GlobalPropertyMobileCard = ({
	PropertyId,
	dispatch,
	session,
	profile_context,
	preview,
	source,
}: {
	PropertyId: any;
	dispatch: any;
	session: any;
	profile_context: any;
	preview?: any;
	source?: string;
}) => {
	const [propertyData, setPropertyData] = React.useState<any>({});

	const [loading, setLoading] = React.useState(true);

	React.useEffect(() => {
		if (PropertyId) {
			fetch(`${process.env.PRODUCTION_API_URL}properties/cards/${PropertyId}`, {
				method: "POST",
				headers: {
					"Content-Type": "text/plain",
				},
			})
				.then((res) => res.json())
				.then((data) => {
					setPropertyData(data.property_data);
					setLoading(false);
				});
		}
	}, [PropertyId]);

	if (loading) {
		return <PropertyLoadingCard />;
	}

	return (
		<React.Fragment>
			<PropertyCard
				profile_context={profile_context}
				session={session}
				dispatch={dispatch}
				loading={loading}
				_id={propertyData._id}
				slug={propertyData.slug}
				transaction_type={propertyData.transaction_type}
				property_type={propertyData.property_type}
				title={propertyData.title}
				images={propertyData.images}
				property_status={propertyData.availability}
				possession_date={propertyData.possession_date}
				tags={propertyData.tags}
				location={propertyData.location ? propertyData.location.title : ""}
				developer={propertyData.builder ? propertyData.builder.name : ""}
				price={propertyData.price}
				price_per_unit={propertyData.price_per_unit}
				area={propertyData.area}
				bhk={propertyData.bhk}
				floors={propertyData.floors}
				no_of_seats={propertyData.no_of_seats}
				saved_content={propertyData.saved_content}
				boosting={propertyData.boost_information}
				created_by={propertyData.created_by}
				show_contact_details={propertyData.show_contact_details}
				source={source}
			/>
		</React.Fragment>
	);
};

export default GlobalPropertyMobileCard;
