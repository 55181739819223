/*

? First, let's import the essentials - React, Layout, styled and Theme.

*/

import { styled } from "@mui/system";
import React from "react";

/* Redux Imports */

import { ServicePrice } from "../../../../redux-magic/sub-interfaces/service-interface";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import { Typography, useTheme } from "@mui/material";
import { CurrencyRupee } from "@mui/icons-material";

/* Library Imports */

import PreparePriceString from "../../../../lib/PreparePriceString";

/* Styled Components */

const Heading = styled(Typography)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	fontWeight: 500,
	fontSize: "1.25rem",
	lineHeight: "1.5rem",
	letterSpacing: "0.009rem",
	margin: "0rem 0rem 0rem 0rem",
}));

const RupeeIcon = styled(CurrencyRupee)(({ theme }) => ({
	margin: "0.15rem 0rem 0rem 0rem",
	fontSize: "1.25rem",
}));

const ServiceListCardPrice = ({ showPrice, price }: { showPrice: boolean; price: ServicePrice }) => {
	const theme = useTheme();

	return (
		<Heading
			sx={{
				margin: showPrice === true ? "0rem 0rem 0rem 0rem" : "0rem 0rem 0rem 0.25rem",
				fontStyle: showPrice === true ? "normal" : "italic",
				fontWeight: showPrice === true ? 500 : 400,
				color: showPrice === true ? theme.palette.text.primary : theme.palette.text.secondary,
				fontSize: showPrice === true ? "1.25rem" : "1.125rem",
			}}
		>
			{price ? price.show_price === true ? <RupeeIcon /> : "" : ""}
			{price
				? price.show_price === true
					? price.min === price.max
						? PreparePriceString(price.max)
						: PreparePriceString(price.min) + " - " + PreparePriceString(price.max)
					: "Price on request"
				: ""}
		</Heading>
	);
};

export default ServiceListCardPrice;
