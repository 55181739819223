/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Redux Imports */

import { Price } from "../../../../../redux-magic/sub-interfaces/sub-interfaces";

/* Component Imports */

import { Typography, Skeleton } from "@mui/material";
import { CurrencyRupee } from "@mui/icons-material";

/* Library Imports */

import PreparePriceString from "../../../../../lib/PreparePriceString";

/* Styled Components */

const Heading = styled(Typography)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	fontWeight: 500,
	fontSize: "1.25rem",
	lineHeight: "1.5rem",
	letterSpacing: "0.009rem",
	margin: "0rem 0rem 0rem 0rem",
}));

const RupeeIcon = styled(CurrencyRupee)(({ theme }) => ({
	margin: "0.15rem 0rem 0rem 0rem",
	fontSize: "1.25rem",
}));

const GridCardPrice = ({ price, loading }: { price: Price; loading: boolean }) => {
	if (loading) {
		return (
			<React.Fragment>
				<Skeleton
					animation="wave"
					variant="text"
					width="75%"
					height="auto"
					style={{ marginBottom: "0.5rem" }}
				/>
			</React.Fragment>
		);
	}

	return (
		<React.Fragment>
			<Heading>
				<RupeeIcon />
				{price !== undefined
					? price
						? price.min === price.max
							? PreparePriceString(price.max)
							: PreparePriceString(price.min) + " - " + PreparePriceString(price.max)
						: "Price not available"
					: ""}
			</Heading>
		</React.Fragment>
	);
};

export default GridCardPrice;
