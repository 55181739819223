/*

? First, let's import the essentials - React, Layout, styled and Theme.

*/

import React from "react";
import { styled } from "@mui/system";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import { Skeleton, Typography } from "@mui/material";

/*

& Next, let's style all the components we intend to use on this page.

*/

const Heading = styled(Typography)(({ theme }) => ({
	fontWeight: 500,
	fontSize: "1.125rem",
	lineHeight: "1.75rem",
	margin: "0.25rem 0rem 1.25rem 0.25rem",
}));

const PropertyListCardTitle = ({ loading, title }: { loading: boolean; title: string }) => {
	if (loading) {
		return (
			<Skeleton
				animation="wave"
				variant="text"
				width="75%"
				height="auto"
			/>
		);
	}

	return (
		<Heading variant="body1">
			{title ? (title.length < 50 ? title : title?.substring(0, 50) + "...") : "Heavie Eden"}
		</Heading>
	);
};

export default PropertyListCardTitle;
