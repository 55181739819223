/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Redux Imports */

import { ServicePrice } from "../../../../redux-magic/sub-interfaces/sub-interfaces";

/* Component Imports */

import { Typography, useTheme, Skeleton } from "@mui/material";
import { CurrencyRupee } from "@mui/icons-material";

/* Library Imports */

import PreparePriceString from "../../../../lib/PreparePriceString";

/* Styled Components */

const Heading = styled(Typography)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	fontWeight: 500,
	fontSize: "1.25rem",
	lineHeight: "1.5rem",
	letterSpacing: "0.009rem",
	margin: "0rem 0rem 0rem 0rem",
}));

const RupeeIcon = styled(CurrencyRupee)(({ theme }) => ({
	margin: "0.15rem 0rem 0rem 0rem",
	fontSize: "1.25rem",
}));

const ServiceCardPrice = ({ price, loading }: { price: ServicePrice; loading: boolean }) => {
	const theme = useTheme();

	if (loading) {
		return (
			<React.Fragment>
				<Skeleton
					animation="wave"
					variant="text"
					width="75%"
					height="auto"
					style={{ marginBottom: "0.5rem" }}
				/>
			</React.Fragment>
		);
	}

	return (
		<React.Fragment>
			<Heading
				sx={{
					margin: price.show_price === true ? "0rem 0rem 0rem 0rem" : "0rem 0rem 0rem 0.25rem",
					fontStyle: price.show_price === true ? "normal" : "italic",
					fontWeight: price.show_price === true ? 500 : 400,
					color: price.show_price === true ? theme.palette.text.primary : theme.palette.text.secondary,
					fontSize: price.show_price === true ? "1.25rem" : "1.125rem",
				}}
			>
				{price ? price.show_price === true ? <RupeeIcon /> : "" : ""}
				{price
					? price.show_price === true
						? price.min === price.max
							? PreparePriceString(price.max)
							: PreparePriceString(price.min) + " - " + PreparePriceString(price.max)
						: "Price on request"
					: ""}
			</Heading>
		</React.Fragment>
	);
};

export default ServiceCardPrice;
