/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Component Imports */

import { Link, Skeleton, Paper, Typography } from "@mui/material";

/* Styled Components */

const PaperContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	background: theme.palette.background.paper,
	width: "22.56rem",
	height: "10.68rem",
	padding: "0.5rem",
	boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
	borderRadius: "8px",
}));

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	gap: "1rem",
	padding: "0.5rem 0rem 0.5rem 0rem",
}));

const ImgContainer = styled("div")(({ theme }) => ({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	height: "6.25rem",
	width: "6.25rem",
}));

const TitleContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	// gap: "0.5rem",
}));

const Title = styled(Typography)(({ theme }) => ({
	color: theme.palette.mode === "dark" ? "#ffffff" : "#000000",
	fontWeight: 400,
	fontSize: "1rem",
	lineHeight: "1.25rem",
	textDecoration: "none",
	textAlign: "center",
	wordBreak: "break-word",
	[theme.breakpoints.down("sm")]: {
		fontSize: "0.875rem",
		lineHeight: "1rem",
	},
}));

const SubTitle = styled(Typography)(({ theme }) => ({
	color: "#F76C35",
	fontWeight: 400,
	fontSize: "0.85rem",
	lineHeight: "1.125rem",
	textDecoration: "none",
	[theme.breakpoints.down("xs")]: {
		fontSize: "0.8rem",
	},
}));

const InnerTextContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	gap: "0.5rem",
}));

const ButtonContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	gap: "0.5rem",
	padding: "0.5rem 0rem 0.5rem 0rem",
}));

const data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
const BusinessProfileLoadingCard = () => {
	return (
		<React.Fragment>
			<>
				{data.map((data: any, index: number) => (
					<PaperContainer key={index}>
						<MainContainer>
							<ImgContainer>
								<Skeleton
									animation="wave"
									variant="text"
									width="10rem"
									height="9rem"
									style={{ marginBottom: "0.5rem", borderRadius: "0.5rem" }}
								/>
							</ImgContainer>
							<TitleContainer>
								<Title>
									<Skeleton
										animation="wave"
										variant="text"
										width="10rem"
										height="100%"
										style={{ marginBottom: "0.5rem" }}
									/>
								</Title>
								<SubTitle>
									<Skeleton
										animation="wave"
										variant="text"
										width="7rem"
										height="100%"
									/>
								</SubTitle>
								<InnerTextContainer>
									<Skeleton
										animation="wave"
										variant="rectangular"
										width="100%"
										height="2rem"
										style={{ margin: "0.5rem 0rem 0.5rem 0rem", borderRadius: "0.5rem" }}
									/>
								</InnerTextContainer>
							</TitleContainer>
						</MainContainer>
						<ButtonContainer>
							<Skeleton
								animation="wave"
								variant="rectangular"
								width="100%"
								height="2.5rem"
								style={{ margin: "0rem 0rem 0.5rem 0rem", borderRadius: "0.5rem" }}
							/>
							<Skeleton
								animation="wave"
								variant="rectangular"
								width="100%"
								height="2.5rem"
								style={{ margin: "0rem 0rem 0.5rem 0rem", borderRadius: "0.5rem" }}
							/>
						</ButtonContainer>
					</PaperContainer>
				))}
			</>
		</React.Fragment>
	);
};

export default BusinessProfileLoadingCard;
