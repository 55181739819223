/* Common imports */

import React, { useRef } from "react";

/* mui imports */

import {
	useTheme,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Divider,
	FormControlLabel,
	Typography,
	Slide,
	Button,
	Autocomplete,
	Popper,
	TextField,
	Checkbox,
} from "@mui/material";
import { styled } from "@mui/system";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import PoolOutlinedIcon from "@mui/icons-material/PoolOutlined";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";

/* redux imports */
import StateInterface from "../../redux-magic/state-interface";
import {
	SearchFilterId,
	SearchFilterOption,
	SearchFilterOptionTextActive,
} from "../../redux-magic/sub-interfaces/search-types";
import { updateSearchFilterActiveOptionsThunk, updateSearchFilterSelectedOptionsThunk } from "../../redux-magic/store";
import { updateSearchFilterAppliedThunk } from "../../redux-magic/thunks";

/* stylings */

const CustomAccordion = styled(Accordion)(({ theme }) => ({
	borderRadius: "16px",
	boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
	"&:: before": {
		display: "none",
	},
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
	padding: "var(--Spacing-spacing-sm, 8px) var(--Spacing-spacing-md, 16px)",
	margin: "0rem",
}));

const CustomPoolOutlinedIcon = styled(PoolOutlinedIcon)(({ theme }) => ({
	marginRight: "1rem",
	color: theme.palette.primary.main,
}));

const CustomArrowIcon = styled(KeyboardArrowDownOutlinedIcon)(({ theme }) => ({
	width: "1.5rem",
	height: "1.5rem",
	borderRadius: "4px",
	color: theme.palette.mode == "dark" ? "#FFFFFF" : "#623816",
	backgroundColor: "#FC801929",
}));

const CustomSelectedIcon = styled(Brightness1Icon)(({ theme }) => ({
	color: theme.palette.primary.main,
	width: "8px",
	marginTop: "-0.25rem",
	marginLeft: "0.25rem",
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	flexWrap: "wrap",
	padding: "1rem",
	margin: "0rem",
	gap: "var(--Spacing-spacing-md, 16px)",
}));

const CheckBoxDiv = styled(Button)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "0.5rem",
	padding: "0.25rem",
	boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
	gap: "0.25rem",
	cursor: "pointer",
	background: "#FC801933",
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "0.813rem",
	fontWeight: "400",
	lineHeight: "1.125rem",
	padding: "3px 6px",
	textTransform: "capitalize",
	color: theme.palette.mode == "dark" ? "#FFFFFF" : "#623816",
}));

const RemoveIcon = styled(RemoveCircleOutlineOutlinedIcon)(({ theme }) => ({
	color: theme.palette.mode == "dark" ? "#FFFFFF" : "#623816",
	height: "1rem",
	width: "1rem",
}));

export const _u_amenities = ({
	search_id,
	amenities,
	dispatch,
}: {
	search_id: SearchFilterId;
	amenities: StateInterface["search_filters_state"]["amenities"];
	dispatch: Function;
}) => {
	const theme = useTheme();

	const AmenityOptions = [
		{
			id: "jogging-track",
			title: "Jogging Track",
			value: "jogging-track",
		},
		{
			id: "swimming-pool",
			title: "Swimming Pool",
			value: "swimming-pool",
		},
		{
			id: "heated-swimming-pool",
			title: "Heated Swimming Pool",
			value: "heated-swimming-pool",
		},
		{
			id: "infinity-pool",
			title: "Infinity Pool",
			value: "infinity-pool",
		},
		{
			id: "toddler-pool",
			title: "Toddler Pool",
			value: "toddler-pool",
		},
		{
			id: "clubhouse",
			title: "Clubhouse",
			value: "clubhouse",
		},
		{
			id: "bonfire-area",
			title: "Bonfire Area",
			value: "bonfire-area",
		},
		{
			id: "park",
			title: "Park",
			value: "park",
		},
		{
			id: "senior-citizen-park",
			title: "Senior Citizen Park",
			value: "senior-citizen-park",
		},
		{
			id: "rain-water-harvesting",
			title: "Rain Water Harvesting",
			value: "rain-water-harvesting",
		},
		{
			id: "security",
			title: "Security",
			value: "security",
		},
		{
			id: "gym",
			title: "Gym",
			value: "gym",
		},
		{
			id: "cctv-surveillance",
			title: "CCTV Surveillance",
			value: "cctv-surveillance",
		},
		{
			id: "amphitheatre",
			title: "Amphitheatre",
			value: "amphitheatre",
		},
		{
			id: "mini-theatre",
			title: "Mini Theatre",
			value: "mini-theatre",
		},
		{
			id: "yoga-deck",
			title: "Yoga Deck",
			value: "yoga-deck",
		},
		{
			id: "landscaped-garden",
			title: "Landscaped Garden",
			value: "landscaped-garden",
		},
		{
			id: "kids-play-area",
			title: "Kids Play Area",
			value: "kids-play-area",
		},
		{
			id: "supermarket",
			title: "Supermarket",
			value: "supermarket",
		},
		{
			id: "atm",
			title: "Atm",
			value: "atm",
		},
		{
			id: "pharmacy",
			title: "Pharmacy",
			value: "pharmacy",
		},
		{
			id: "tennis-court",
			title: "Tennis Court",
			value: "tennis-court",
		},
		{
			id: "basketball-court",
			title: "Basketball Court",
			value: "basketball-court",
		},
		{
			id: "badminton-court",
			title: "Badminton Court",
			value: "badminton-court",
		},
		{
			id: "table-tennis",
			title: "Table Tennis",
			value: "table-tennis",
		},
		{
			id: "indoor-games",
			title: "Indoor Games",
			value: "indoor-games",
		},
		{
			id: "billiards",
			title: "Billiards",
			value: "billiards",
		},
		{
			id: "skating-rink",
			title: "Skating Rink",
			value: "skating-rink",
		},
		{
			id: "volleyball-court",
			title: "Volleyball Court",
			value: "volleyball-court",
		},
		{
			id: "sand-pit",
			title: "Sand Pit",
			value: "sand-pit",
		},
		{
			id: "cricket-practice-net",
			title: "Cricket Practice Net",
			value: "cricket-practice-net",
		},
		{
			id: "football-turf",
			title: "Football Turf",
			value: "football-turf",
		},
		{
			id: "lift",
			title: "Lift",
			value: "lift",
		},
		{
			id: "cafeteria",
			title: "Cafeteria",
			value: "cafeteria",
		},
		{
			id: "car-parking",
			title: "Car Parking",
			value: "car-parking",
		},
		{
			id: "covered-car-parking",
			title: "Covered Car Parking",
			value: "covered-car-parking",
		},
		{
			id: "visitor-parking",
			title: "Visitor Parking",
			value: "visitor-parking",
		},
		{
			id: "electric-car-charging-station",
			title: "Electric Car Charging Station",
			value: "electric-car-charging-station",
		},
		{
			id: "library",
			title: "Library",
			value: "library",
		},
		{
			id: "reading-room",
			title: "Reading Room",
			value: "reading-room",
		},
		{
			id: "multipurpose-hall",
			title: "Multipurpose Hall",
			value: "multipurpose-hall",
		},
		{
			id: "jacuzzi",
			title: "Jacuzzi",
			value: "jacuzzi",
		},
		{
			id: "sauna",
			title: "Sauna",
			value: "sauna",
		},
		{
			id: "spa",
			title: "Spa",
			value: "spa",
		},
		{
			id: "barbecue-area",
			title: "Barbecue Area",
			value: "barbecue-area",
		},
		{
			id: "squash-court",
			title: "Squash Court",
			value: "squash-court",
		},
		{
			id: "power-backup",
			title: "Power Backup",
			value: "power-backup",
		},
		{
			id: "helipad",
			title: "Helipad",
			value: "helipad",
		},
		{
			id: "sewage-treatment-plant",
			title: "Sewage Treatment Plant",
			value: "sewage-treatment-plant",
		},
		{
			id: "intercom",
			title: "Intercom",
			value: "intercom",
		},
		{
			id: "water-softening-plant",
			title: "Water Softening Plant",
			value: "water-softening-plant",
		},
		{
			id: "fire-safety-system",
			title: "Fire Safety System",
			value: "fire-safety-system",
		},
		{
			id: "butterfly-garden",
			title: "Butterfly Garden",
			value: "butterfly-garden",
		},
		{
			id: "cycling-track",
			title: "Cycling Track",
			value: "cycling-track",
		},
		{
			id: "waiting-lounge",
			title: "Waiting Lounge",
			value: "waiting-lounge",
		},
		{
			id: "solar-lighting",
			title: "Solar Lighting",
			value: "solar-lighting",
		},
		{
			id: "salon",
			title: "Salon",
			value: "salon",
		},
		{
			id: "meditation-zone",
			title: "Meditation Zone",
			value: "meditation-zone",
		},
		{
			id: "maintenance-staff",
			title: "Maintenance Staff",
			value: "maintenance-staff",
		},
		{
			id: "outdoor-lounge",
			title: "Outdoor Lounge",
			value: "outdoor-lounge",
		},
		{
			id: "health-clinic",
			title: "Health Clinic",
			value: "health-clinic",
		},
		{
			id: "restaurant",
			title: "Restaurant",
			value: "restaurant",
		},
		{
			id: "golf-course",
			title: "Golf Course",
			value: "golf-course",
		},
		{
			id: "waste-management-plant",
			title: "Waste Management Plant",
			value: "waste-management-plant",
		},
		{
			id: "business-centre",
			title: "Business Centre",
			value: "business-centre",
		},
		{
			id: "party-counter",
			title: "Party Counter",
			value: "party-counter",
		},
		{
			id: "service-lift",
			title: "Service Lift",
			value: "service-lift",
		},
		{
			id: "gas-pipeline",
			title: "Gas Pipeline",
			value: "gas-pipeline",
		},
		{
			id: "play-school",
			title: "Play School",
			value: "play-school",
		},
		{
			id: "terrace-private-garden",
			title: "Terrace / Private Garden",
			value: "terrace-private-garden",
		},
	];

	const [state, setState] = React.useState<Array<SearchFilterOption>>([]);

	const [slide, setSlide] = React.useState<boolean>(false);

	const [autoCompleteOpen, setAutoCompleteOpen] = React.useState<boolean | undefined>(false);

	const popperRef = useRef<HTMLDivElement | null>(null);

	const handleUpdateAmenities = (newValue: Array<SearchFilterOption>) => {
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: newValue as Array<SearchFilterOption>,
			}),
		);

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions: amenities.active_options.map((item: SearchFilterOptionTextActive) => ({
					...item,
					is_selected: newValue.some((obj) => obj.id === item.id) ? true : false,
				})),
			}),
		);
	};

	React.useEffect(() => {
		// lets check if the amenities is selected or not
		let isAmenitiesSelected = amenities ? (amenities.selected_options.length !== 0 ? true : false) : false;

		// lets set the isApplied value to true if the amenities is selected
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: search_id, isApplied: isAmenitiesSelected }));
	}, [amenities, search_id, dispatch]);

	return (
		<CustomAccordion
			disableGutters={true}
			square={true}
			onChange={() => {
				setSlide(!slide);
			}}
		>
			<CustomAccordionSummary
				expandIcon={<CustomArrowIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				<CustomPoolOutlinedIcon /> Amenities {state.length > 0 && <CustomSelectedIcon />}
			</CustomAccordionSummary>
			<Divider
				sx={{
					borderBottom: "1px solid var(--Other-New-Divider, rgba(255, 255, 255, 0.12))",
				}}
			/>
			<Slide
				direction="up"
				in={slide}
				mountOnEnter
				unmountOnExit
			>
				<CustomAccordionDetails>
					<Autocomplete
						multiple
						freeSolo
						open={autoCompleteOpen}
						PopperComponent={(props, ref) => (
							<Popper
								{...props}
								ref={popperRef}
								placement="top"
							/>
						)}
						sx={{
							width: "100%",
							margin: "1.5rem 0rem 1rem 0rem",
							[theme.breakpoints.down("sm")]: {
								margin: "1rem 0rem 1.5rem 0rem",
							},
						}}
						id="amenity-options"
						value={amenities.selected_options as SearchFilterOption[]}
						onChange={(event, newValue: SearchFilterOption[]) => {
							setState([...newValue]);
							handleUpdateAmenities(newValue);
						}}
						options={AmenityOptions}
						getOptionLabel={(option: SearchFilterOption) => option.title}
						renderTags={(value: SearchFilterOption[]) =>
							value.map((option: SearchFilterOption, index: number) => {
								return (
									<CheckBoxDiv
										key={index}
										onClick={(e) => {
											// Prevent the Autocomplete dropdown from being opened
											e.stopPropagation();
											// Update the state to remove the clicked option
											setState((prev) => prev.filter((item) => item.id !== option.id));
											// Optionally, call handleUpdateAmenities or similar function to update elsewhere
											handleUpdateAmenities(state.filter((item) => item.id !== option.id));
										}}
									>
										<Text>{option.title}</Text>
										<RemoveIcon />
									</CheckBoxDiv>
								);
							})
						}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								label="Start typing here"
								onBlur={() => {
									setAutoCompleteOpen(false);
								}}
								onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
									if (e.target.value !== "") {
										setAutoCompleteOpen(true);
									} else if (e.target.value.length < 2) {
										setAutoCompleteOpen(false);
									}
								}}
							/>
						)}
						renderOption={(props, option) => (
							<li {...props}>
								<Checkbox
									icon={<CheckBoxOutlineBlankIcon />}
									checkedIcon={<CheckBoxIcon />}
									checked={state.some((obj) => obj.id === (option as SearchFilterOption).id)}
								/>
								{(option as SearchFilterOption).title}
							</li>
						)}
					/>
				</CustomAccordionDetails>
			</Slide>
		</CustomAccordion>
	);
};
